import React from 'react';
import PropTypes from 'prop-types';
import inputs from './Inputs';

const Input = ({ type = 'Text', ...props }) => {
  const InputComponent =
    inputs[type] || (() => <div>Incorrect input type</div>);

  return <InputComponent {...props} />;
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Input;

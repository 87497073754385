import ClaytonHomes from './ClaytonHomes';
import CrosslandHomes from './CrosslandHomes';
import FreedomHomes from './FreedomHomes';
import InternationalHomes from './InternationalHomes';
import LuvHomes from './LuvHomes';
import OakwoodHomes from './OakwoodHomes';
import TruValue from './TruValue';

export default {
  ClaytonHomes,
  CrosslandHomes,
  FreedomHomes,
  InternationalHomes,
  LuvHomes,
  OakwoodHomes,
  TruValue,
};

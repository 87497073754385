import styled from 'styled-components';

export default styled.div`${({ theme, imageSrc, isSubmittingForm }) => `
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: ${theme.maxWidths.ppcPages};
  opacity: ${isSubmittingForm ? 0 : 1};
  z-index: 2;

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
  }

  .error .input_wrapper input {
    border-color: ${theme.colors.vmfRed};
  }

  .ppc-header-form {
    position: absolute;
    background: white;
    min-height: 25.4375rem;
    width: 100%;
    max-width: 31.0625rem;
    right: 0;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05);
    max-width: 31.0625rem;
    width: 100%;

    &__button-container {
      display: flex;
    }

    &__first-button {
      display: inline-block;
      margin: auto;
      margin-top: 1.06125rem;
    }

    &__heading {
      background-color: ${theme.colors.babyBlueTint};
      padding: 1.74375rem;
      box-sizing: border-box;
      ${
        imageSrc
          ? `
            background-image: url("${imageSrc}");
            background-repeat: no-repeat;
            background-position: 0% 100%;
            background-size: contain;
            width: 100%;
            min-height: 10.375rem;
          ` : ''
      }
    }

    &__header {
      width: 100%;
      text-align: center;
      line-height: 23px;
      letter-spacing: -.25px;
    }

    &__sub-header {
      text-align: center;
      margin: auto;
      margin-top: 1.25rem;
      max-width: 392px;
      color: ${theme.colors.vmfBlue};
    }

    &__form-intro {
      margin: auto;
      margin-top: 0.8rem;
      font-size: ${theme.type.caption};
      font-weight: 400;
      line-height: ${theme.lineHeights.caption.tight};
      max-width: 392px;
      text-align: center;
    }

    &__form-body {
      margin: auto;
      margin-top: 1rem;
      max-width: 392px;
      padding-bottom: 1.75rem;

      label {
        .input_wrapper {
          margin-top: 1.375rem;
        }

        .input-error {
          color: ${theme.colors.vmfRed};
        }

        &:first-of-type {
          .input_wrapper {
            margin-top: 0rem;
          }
        }
        
      }

      input {
        box-sizing: border-box;
        border: 1px solid ${theme.colors.grey2};
        padding: 1rem 0.8rem;
        width: 100%;
        font-size: ${theme.type.paragraph.default};
        font-weight: 400;

        &::placeholder {
          color: ${theme.colors.grey1};
        }
        
      }
      
    }

    &__error {
      padding: 0rem 2rem;
      text-align: center;
      margin: 4rem auto;
    }

    &__disclaimer * {
      margin-top: 1.31875rem;
      font-weight: 400;
      font-size: ${theme.type.paragraph.xs};
      line-height: ${theme.lineHeights.paragraph.xs};
    }

    &__form-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      button, a {
        font-size: 1rem;
        font-weight: 700;
        text-align: center;
        padding: 0 1.25rem;
        height: 3.1875rem;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
    }

    &__second-button {
      margin-top: 1.5rem;
      display: block;
      width: auto;
    }

    &__loading {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 2rem;

      h3 {
        margin-top: 2rem;
      }

      @media (prefers-reduced-motion: no-preference) {
        .icon {
          &-smiling_sun {
            &--rays {
              animation: sunRaysLoop linear 15s forwards infinite;
              transform-origin: 50% 50%;
            }
          }
        }
      
        ${props => props.theme.animations.sunRaysLoop}
      }
    }
    
  }

  @media (max-width: ${theme.breakpoints.desktop}) {
    position: static;
    top: 0;
    left: 0;
    transform: translateX(0px);

    .ppc-header-form {
      position: static;
      max-width: 100%;
      width: 100%;
    }
    
  }

  @media (max-width: ${theme.breakpoints.tablet}) {
    .ppc-header-form {

      &__heading {
        padding-left: ${theme.padding.inner};
        padding-right: ${theme.padding.inner};
      }

      &__body {
        padding: 0 ${theme.padding.inner};
      }
      
    }
  }
  
`}`;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InView from '../../../components/Shared/InView';
import Styles from './styles';
import Form from './Form';
import V1 from './V1';
import V2 from './V2';
import V3 from './V3';
import V4 from './V4';
import V5 from './V5';
import noop from '../../../util/noop';

const headerVersions = {
  V1: 'V1: Home Data',
  V2: 'V2: Promo Callout',
  V3: 'V3: No Payment',
  V4: 'V4: Basic',
  V5: 'V5: Full Image Basic',
};

const Header = ({
  isSubmittingForm = false,
  onSubmittingFormChange = noop,
  content,
  triggerGTMEvent = () => noop,
  ...props
}) => {
  const HeaderVersion = useMemo(() => {
    if (!content?.heroVersion) return null;

    switch (content.heroVersion) {
      case headerVersions.V1:
        return V1;
      case headerVersions.V2:
        return V2;
      case headerVersions.V3:
        return V3;
      case headerVersions.V4:
        return V4;
      case headerVersions.V5:
        return V5;
      default:
        return null;
    }
  }, [content]);

  if (!content || !HeaderVersion) return <></>;

  return (
    <InView>
      <Styles>
        <HeaderVersion content={content} {...props} />
        <Form
          webForm={content?.webForm}
          imageAlt={content?.webFormImage?.fields?.description}
          imageSrc={content?.webFormImage?.fields?.file?.url}
          isSubmittingForm={isSubmittingForm}
          onSubmittingFormChange={onSubmittingFormChange}
          triggerGTMEvent={triggerGTMEvent}
        />
      </Styles>
    </InView>
  );
};

Header.propTypes = {
  content: PropTypes.object,
};

export default Header;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Picture from '../../components/Shared/Picture';
import Styles from './ParagraphSection.styled';
import InView from '../../components/Shared/InView';
import theme from '../../styles/theme';
import ReactMarkdown from 'react-markdown';
import SimpleAnimation from '../../styles/Components/SimpleAnimation';
import ArrowIcon from '../Shared/ArrowIcon';

const colorStyleKeys = {
  DEFAULT: 'Default Style',
  WHITE: 'White',
  VMF_BLUE: 'VMF Blue',
};

export const imageLocationKeys = {
  RIGHT: 'Right Side',
  LEFT: 'Left Side',
};

export const colorStyles = {
  [colorStyleKeys.DEFAULT]: {
    background: theme.colors.white,
    headingColor: theme.colors.darkBlue,
    introColor: theme.colors.grey1,
    linkColor: theme.colors.vmfGreen2,
  },
  [colorStyleKeys.WHITE]: {
    background: theme.colors.white,
    headingColor: theme.colors.darkBlue,
    introColor: theme.colors.grey1,
    linkColor: theme.colors.vmfGreen2,
  },
  [colorStyleKeys.VMF_BLUE]: {
    background: theme.colors.vmfBlue,
    headingColor: theme.colors.white,
    introColor: theme.colors.white,
    linkColor: theme.colors.white,
  },
};

const ParagraphSection = ({
  colorStyle = colorStyleKeys.DEFAULT,
  heading = '',
  intro = '',
  disclaimer = '',
  linkUrl = '',
  linkText = '',
  imageSrc = '',
  imageAlt = '',
  imageLocation = imageLocationKeys.RIGHT,
  iconSrc = '',
  ...props
}) => {
  return (
    <InView>
      <Styles
        {...props}
        colorStyle={
          colorStyles[colorStyle] || colorStyles[colorStyleKeys.DEFAULT]
        }
        imageLocation={imageLocation}
      >
        <div className='paragraph-module-inner'>
          <div className='paragraph-module-content'>
            {iconSrc && (
              <SimpleAnimation.div
                slideDirection='up'
                className='paragraph-module-content__icon'
              >
                <img src={iconSrc} />
              </SimpleAnimation.div>
            )}
            {heading && (
              <SimpleAnimation.h3
                slideDirection='up'
                delay={0.4}
                className='paragraph-module-content__heading'
              >
                {heading}
              </SimpleAnimation.h3>
            )}
            {intro && (
              <SimpleAnimation.div
                slideDirection='up'
                className='paragraph-module-content__intro'
                delay={0.6}
              >
                <ReactMarkdown source={intro} />
              </SimpleAnimation.div>
            )}
            {linkUrl && linkText && (
              <SimpleAnimation.div
                slideDirection='up'
                className='paragraph-module-content__link'
                delay={0.6}
              >
                <Link to={linkUrl}>
                  {linkText}
                  <ArrowIcon />
                </Link>
              </SimpleAnimation.div>
            )}
          </div>
          <SimpleAnimation.div className='paragraph-module-image'>
            <Picture src={imageSrc} alt={imageAlt} />
            <div className='paragraph-module-image__shadow' />
          </SimpleAnimation.div>
        </div>
      </Styles>
    </InView>
  );
};

ParagraphSection.propTypes = {
  colorStyle: PropTypes.oneOf(Object.values(colorStyleKeys)),
  heading: PropTypes.string,
  intro: PropTypes.string,
  disclaimer: PropTypes.string,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  imageLocation: PropTypes.oneOf(Object.values(imageLocationKeys)),
  iconSrc: PropTypes.string,
};

export default ParagraphSection;

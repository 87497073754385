import styled from 'styled-components';

import { imageLocationKeys } from './ParagraphSection';

const imageShadowOffset = {
  desktop: '1.89875rem',
  mobile: '1.5625rem',
}

export default styled.section`${({ theme, colorStyle, imageLocation }) => `
  min-height: 28.875rem;
  width: 100%;
  background: ${colorStyle.background};
  color: ${colorStyle.introColor};

  h3, p {
    margin: 0;
  }

  .paragraph-module-inner {
    display: flex;
    flex-direction: ${imageLocation === imageLocationKeys.LEFT ? 'row-reverse' : 'row'};
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 3.1225rem 0 4.1275rem 0;
    max-width: 69.4375rem;
    margin: auto;
  }

  .paragraph-module-content {
    max-width: 30.625rem;

    &__icon img {
      max-height: 3.75rem;
    }

    &__heading {
      margin-top: 0.875rem;
      color: ${colorStyle.headingColor};
    }

    &__intro {
      margin-top: 0.875rem;
      display: block;
      color: ${colorStyle.introColor};
    }

    &__link {
      margin-top: 1.125rem;
      display: inline-flex;
      width: auto;

      a svg {
        transition: all .3s;
      }

      :hover {
        a svg {
          transform: translateX(.4rem);
        }
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-family: ${theme.fonts.primary};
        font-weight: 700;
        font-size: ${theme.type.button.large};
        line-height: 19px;
        color: ${colorStyle.linkColor};

        svg {
          height: 1.1rem;
          width: 1.1rem;
          margin-left: 0.5rem;
          fill: ${colorStyle.linkColor};
        }
        
      }
      
    }
    
  }

  .paragraph-module-image {
    max-width: 32.5rem;
    max-height: 21.625rem;
    position: relative;
    width: 90%;

    picture {
      position: relative;
      z-index: 4;
      width: 100%;
    }

    picture img {
      max-width: 32.5rem;
      max-height: 21.625rem;
      width: 100%;
    }

    &__shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      background: ${theme.colors.babyBlueTint};
      top: ${imageShadowOffset.desktop};
      left: ${imageLocation === imageLocationKeys.LEFT ? '-' : ''}${imageShadowOffset.desktop};
      z-index: 1;
      opacity: 0.25;
    }
    
  }

  @media (max-width: 1272px) {
    .paragraph-module-inner {
      box-sizing: border-box;
      margin: 0;
      flex-direction: column-reverse;
      align-items: center;
      padding: 4.215rem ${theme.padding.inner} 4.1037rem ${theme.padding.inner};

      .paragraph-module-content {
        margin-top: 4.068125rem;
      }
      
    }

    .paragraph-module-content {
      margin-top: 4.0681rem;
    }

    .paragraph-module-image__shadow {
      top: 6%;
      left: 6%;
    }
  }
  
`}`;
  
import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Styles from './PPC.styled';
import Header from './Header';
import CustomerStories from '../../components/CustomerStories';
import FindAHomeCenter from '../../components/FindAHomeCenter';
import VideoImageSection from '../../components/VideoImageSection';
import HomePartners from '../../components/HomePartners';
import ParagraphSection from '../../components/ParagraphSection';
import SnippetSection from '../../components/SnippetSection';
import CalculatorSection from '../../components/CalculatorSection';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import useGTM from '../../hooks/useGTM';

const fetchHomeDataVersions = ['V1: Home Data'];

const PPC = ({
  data,
  isLoading,
  homeData,
  isSubmittingForm,
  setIsSubmittingForm,
}) => {
  const {
    customerStoriesSection,
    disclaimer,
    findHomeCenter,
    homePartners,
    homePartnersBackgroundColor,
    lpHeroSection,
    mortgageCalculator,
    phoneNumber,
    slug,
    snippetSection,
    textSections,
    titleTag,
    metaDescription,
    videoImageSection,
    videoImageSkin,
  } = data;

  const [triggerGTMEvent] = useGTM();

  return (
    <Styles>
      <Header
        content={lpHeroSection}
        homeData={homeData}
        isSubmittingForm={isSubmittingForm}
        onSubmittingFormChange={setIsSubmittingForm}
        triggerGTMEvent={triggerGTMEvent}
      />
      {!isLoading && !isSubmittingForm && (
        <>
          {(videoImageSection?.media?.fields?.file?.url ||
            videoImageSection?.embeddedContent) && (
            <VideoImageSection
              skin={videoImageSkin}
              imageSrc={videoImageSection?.media?.fields?.file?.url}
              imageDescription={videoImageSection?.media?.fields?.description}
              embeddedContentID={videoImageSection?.embeddedContent}
              mediaType={videoImageSection?.mediaType}
              heading={
                videoImageSection?.mediaName ||
                videoImageSection?.embeddedContentHeader
              }
              subHeading={
                videoImageSection?.description ||
                videoImageSection?.embeddedContentSubHeader
              }
              videoSource={videoImageSection?.videoSource}
              onPlay={triggerGTMEvent({
                name: 'vmf.cta_click',
                location: 'video or featured image content module',
                category: 'loan_education',
                action: 'video',
                label: 'watch_video',
              })}
              thumbnailSource={videoImageSection?.thumbnail?.fields?.file?.url}
              thumbnailAlt={videoImageSection?.thumbnail?.fields?.description}
            />
          )}
          {textSections &&
            textSections?.length > 0 &&
            textSections.map((section, index) => (
              <ParagraphSection
                key={`ppc-paragraph-section-${index}`}
                colorStyle={section?.sectionBackgroundColor?.split(':')?.[0]}
                heading={section?.sectionHeader}
                intro={section?.sectionIntro}
                disclaimer={section.disclaimer}
                linkUrl={section?.sectionLink}
                linkText={section?.sectionLinkText}
                imageSrc={section?.featuredImage?.fields?.file?.url}
                imageAlt={section?.featuredImage?.fields?.description}
                imageLocation={section?.imageLocation}
                iconSrc={section?.sectionIcon?.fields?.file?.url}
              />
            ))}
          {snippetSection?.snippets?.length && (
            <SnippetSection
              theme={snippetSection?.sectionBackgroundColor?.split(':')?.[0]}
              heading={snippetSection?.snippetSectionHeader}
              snippets={snippetSection?.snippets?.map(snippet => ({
                header: snippet.snippetHeader,
                iconUrl: snippet?.snippetIcon?.fields?.file?.url,
                iconAlt: snippet?.snippetIcon?.fields?.description,
                text: snippet.snippetText,
              }))}
            />
          )}
          {mortgageCalculator && (
            <CalculatorSection
              header='Mortgage Calculator'
              subHeader='Estimate what your potential monthly mortgage payment may look like. Calculator results are only estimates.'
              disclaimer={
                <p className='type-xs'>
                  <u>Please note:</u> The results from the calculator are only
                  estimates. There are additional costs to buying a home that
                  may not be reflected in this calculator. Always compare loan
                  offers you may receive before making your decision. Contact us
                  to talk to a licensed loan specialist about loan options
                  specific to your financial needs.{' '}
                </p>
              }
              disclaimerPosition='BELOW_PIE_CHART'
            />
          )}
          {homePartners && (
            <HomePartners backgroundColor={homePartnersBackgroundColor} />
          )}
          {customerStoriesSection?.customerSTories?.length && (
            <CustomerStories
              stories={customerStoriesSection?.customerSTories}
              backgroundColor={
                customerStoriesSection?.customerStoriesBackgroundColor
              }
              header={customerStoriesSection?.customerStoriesSectionHeader}
            />
          )}
          {findHomeCenter && (
            <FindAHomeCenter
              triggerGTMEvent={triggerGTMEvent({
                name: 'vmf.cta_click',
                location: 'search bar for find a home center',
                category: 'home_buying',
                action: 'search',
                label: 'home_center_search',
              })}
              className='ppc-find-home-center'
            />
          )}
          {disclaimer && (
            <Disclaimer className='ppc-disclaimer'>
              <ReactMarkdown
                source={disclaimer}
                parserOptions={{ commonmark: true }}
              />
            </Disclaimer>
          )}
        </>
      )}
    </Styles>
  );
};

PPC.propTypes = {
  data: PropTypes.object,
  error: PropTypes.object,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

export default PPC;

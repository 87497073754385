import React, { useEffect, useMemo, useState } from 'react';
import ErrorComponent from '../../components/Error/Error';
import Layout from '../../components/Layout/Layout';
import Loading from '../../components/Loading/Loading';
import SEO from '../../components/SEO/SEO';
import PPC from '../PPC';
import LPThankYouPage from '../LPThankYouPage';
import {
  updateHeaderContactNumber,
  useAppContext,
} from '../../contexts/AppContext';
import { getPPCLandingPage } from '../../services/ppc';
import { getLpThankYouPage } from '../../services/lpThankYouPage';
import { getModelDetails } from '../../services/claytonHomesApi';

const fetchHomeDataVersions = ['V1: Home Data'];

const pageTypes = {
  PPC: 'PPC',
  LP_THANK_YOU_PAGE: 'LP_THANK_YOU_PAGE',
};

const Pages = ({ data, error, isLoading, className, homeData, pageType }) => {
  const [, dispatch] = useAppContext();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  if (error) {
    console.error(error);
    return (
      <Layout>
        <ErrorComponent />
      </Layout>
    );
  }

  useEffect(() => {
    if (data?.phoneNumber) {
      updateHeaderContactNumber(dispatch, data?.phoneNumber);
    }
  }, [data?.phoneNumber]);

  return (
    <Layout isLoading={isLoading} className={className}>
      <SEO title={data?.titleTag} description={data?.description} />
      <Loading isLoading={isLoading || isSubmittingForm} />
      {pageType === pageTypes.PPC ? (
        <PPC
          data={data}
          isLoading={isLoading}
          homeData={homeData}
          isSubmittingForm={isSubmittingForm}
          setIsSubmittingForm={setIsSubmittingForm}
        />
      ) : (
        <LPThankYouPage data={data} />
      )}
    </Layout>
  );
};

Pages.getInitialProps = async ({ match }) => {
  const { slug } = match.params;
  let homeData = {};
  let pageType = null;

  try {
    let data = await getPPCLandingPage(`/pages/${slug}/`);

    if (data.status !== 200) {
      data = await getLpThankYouPage(`/pages/${slug}/`);

      if (data.status !== 200) {
        throw data.statusText;
      }

      if (!pageType) pageType = pageTypes.LP_THANK_YOU_PAGE;
    }

    if (!pageType) pageType = pageTypes.PPC;

    if (pageType === pageTypes.PPC) {
      if (
        data?.data?.result?.lpHeroSection?.homeData &&
        fetchHomeDataVersions.includes(
          data?.data?.result?.lpHeroSection?.heroVersion
        )
      ) {
        const modelDetails = await getModelDetails(
          data?.data?.result?.lpHeroSection?.homeData
        );

        if (modelDetails.status !== 200) {
          throw modelDetails.statusText;
        }

        if (modelDetails?.data?.length) {
          const { baths, beds, modelDescription, maxSquareFeet } =
            modelDetails.data[0];
          homeData = { baths, beds, modelDescription, maxSquareFeet };
        }
      }
    }

    return {
      data: data.data.result,
      homeData,
      pageType,
    };
  } catch (error) {
    return {
      error,
    };
  }
};

export default Pages;

import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import Styles from './LPThankYouPage.styled';
import InView from '../../components/Shared/InView';
import SimpleAnimation from '../../styles/Components/SimpleAnimation';
import ButtonLink from '../../components/Shared/ButtonLink';
import url from '../../util/url';
import Picture from '../../components/Shared/Picture';
import Disclaimer from '../../components/Disclaimer/Disclaimer';

const LPThankYouPage = ({ data }) => {
  const { disclaimer, heroCTA, heroImage } = data;

  const [url1, url2] = useMemo(() => {
    return [
      url(heroCTA?.firstCTAButtonUrl, { source: 'ppc_vmf_search' }),
      url(heroCTA?.secondButtonUrl, { utm_medium: 'ppc' }),
    ];
  }, [heroCTA?.firstCTAButtonUrl, heroCTA?.secondButtonUrl]);

  return (
    <InView>
      <Styles>
        <div className='thank-you-content'>
          <SimpleAnimation.h1 className='thank-you-content-heading'>
            {heroCTA?.ctaHeader}
          </SimpleAnimation.h1>
          <SimpleAnimation.div className='thank-you-content-intro' delay={0.4}>
            <ReactMarkdown source={heroCTA?.ctaIntro} />
          </SimpleAnimation.div>
          <div className='thank-you-content-buttons'>
            {heroCTA?.firstCTAButtonText && heroCTA?.firstCTAButtonUrl && (
              <ButtonLink
                contentfulColors={heroCTA?.buttonAndTextColors}
                to={url1}
                isExternal={
                  heroCTA?.firstCTAButtonUrl?.slice(0, 9) === '/applynow'
                }
              >
                {heroCTA?.firstCTAButtonText}
              </ButtonLink>
            )}
            {heroCTA?.secondCTAButtonText && heroCTA?.secondCTAButtonUrl && (
              <ButtonLink
                contentfulColors={heroCTA?.buttonAndTextColors}
                inverse={
                  heroCTA?.secondButtonColor === 'Inverse colors (default)'
                }
                to={url2}
                isExternal={
                  heroCTA?.secondButtonUrl?.slice(0, 9) === '/applynow'
                }
              >
                {heroCTA?.secondCTAButtonText}
              </ButtonLink>
            )}
          </div>
        </div>
        <div className='thank-you-image-container'>
          <Picture
            src={heroImage?.fields?.file?.url}
            alt={heroImage?.fields?.description}
          />
        </div>
      </Styles>
      {disclaimer && (
        <Disclaimer>
          <ReactMarkdown source={disclaimer} />
        </Disclaimer>
      )}
    </InView>
  );
};

export default LPThankYouPage;

import styled from 'styled-components';

export default styled.section`
  box-sizing: border-box;
  width: 100%;
  min-height: 20.96625rem;
  text-align: center;
  padding-top: 5.616875rem;
  padding-bottom: 2.86125rem;
  background-color: ${({ colors }) => colors.backgroundColor};

  h2 {
    color: ${({ colors }) => colors.textColor};
    margin: 0;
    padding: 0;
    margin-bottom: 1.05625rem;
  }

  .partners-list {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: auto;
    
    .partner-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .partner-icon {
    margin-right: 1.903125rem;

    &--no-margin {
      margin-right: 0;
    }
  }

  @media (max-width: 1250px) {
    .partners-list {
      flex-direction: column;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    .partners-list {
      .partner-row {

        &__double {
          justify-content: space-between;

          .partner-icon:nth-of-type(2) {
            margin-right: 0;
          }
          
        }

        &__large {
          flex-direction: column;
        }

      }
    }
  }

  @media (max-width: 300px) {
    .partner-icon svg {
      max-width: 35vw;
    }
  }
  
`

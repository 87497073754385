import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import Styles from './CustomerStories.styled';
import theme from '../../styles/theme';
import Picture from '../Shared/Picture';
import SimpleAnimation from '../../styles/Components/SimpleAnimation';
import InView from '../../components/Shared/InView';

const CustomerStories = ({
  stories = [],
  backgroundColor = '',
  header = '',
}) => {
  const colors = useMemo(() => {
    switch (backgroundColor) {
      case 'Transparent: #00000000':
      default:
        return {
          background: 'transparent',
          headerColor: theme.colors.darkBlue,
          storyBodyColor: theme.colors.grey1,
          storyNameColor: theme.colors.darkBlue,
        };
    }
  }, [backgroundColor]);

  const mappedStories = useMemo(() => {
    if (stories.length) {
      return stories.map(story => ({
        customerName: story.customerName,
        location: story.location,
        story: story.story,
        storyDate: story.storyDate,
        imageDescription: story.image.fields.description,
        imageUrl: story.image.fields.file.url,
      }));
    }

    return [];
  }, [stories]);

  return (
    <InView>
      <Styles colors={colors}>
        <div className='customer-stories-inner'>
          <SimpleAnimation.h1 slideDirection='up'>{header}</SimpleAnimation.h1>
          <div className='customer-stories-content'>
            {mappedStories.length &&
              mappedStories.map((story, index) => (
                <SimpleAnimation.div
                  className='customer-story'
                  delay={0.4 + 0.2 * index}
                  slideDirection='up'
                  key={`mapped-stories-story-${index}`}
                >
                  <Picture
                    src={story.imageUrl}
                    alt={story.imageUrl}
                    className='customer-story-image'
                  />
                  <div className='customer-story-details'>
                    <ReactMarkdown
                      className='customer-story-content'
                      source={story.story}
                    />
                    <label className='customer-story-details__customer-name'>
                      {story.customerName}, {story.location}
                    </label>
                  </div>
                </SimpleAnimation.div>
              ))}
          </div>
        </div>
      </Styles>
    </InView>
  );
};

export default CustomerStories;

import axios from 'axios';
import {
  getBaseChApiUrl,
  getEnvironment,
  getRequestHeaders,
} from '../util/config';

export const getModelDetails = async (modelNumbers = [], headers = {}) => {
  const query =
    typeof modelNumbers === 'string'
      ? `modelNumbers=${modelNumbers}`
      : modelNumbers
          .map(modelNumber => `modelNumbers=${modelNumber}`)
          .join('&');

  const url = `${getBaseChApiUrl()}/modelDetails?${query}`;

  return await axios.get(url, {
    ...headers,
    ...getRequestHeaders(),
  });
};

export const getModelDetailsSSR = async (modelNumbers = []) => {
  const env = getEnvironment();
  const fs = await import('fs');
  const https = await import('https');

  const getCertAgent = () =>
    ['dev', 'itg', 'qua'].includes(env) && fs && fs?.readFileSync !== undefined
      ? new https.Agent({
          ca: fs.readFileSync('./ClaytonRootCA.cer'),
          keepAlive: false,
        })
      : null;

  const getAuthHeader = () => {
    const httpsAgent = getCertAgent();
    return { httpsAgent };
  };

  return await getModelDetails(modelNumbers, { ...getAuthHeader() });
};

export default (str = window.location.search) =>
  str
    .substring(1)
    .split('&')
    .reduce((_params, current) => {
      const [label, value] = current.split('=');
      return {
        ..._params,
        [label]: value,
      };
    }, {});

export default (query = {}, initialQuery = '') =>
  Object.keys(query).reduce(
    (previous, key) => {
      if (key && query[key]) {
        const value = query[key];

        previous += `${previous.length > 0 ? '&' : ''}${key}=${value}`;
      }

      return previous;
    },
    initialQuery?.replace('?', '') || ''
  );

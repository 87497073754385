import React from 'react';

const FreedomHomes = ({
  color = '#7C858D',
  backgroundColor = null,
  ...props
}) => {
  return (
    <svg
      width='136px'
      height='83px'
      viewBox='0 0 136 83'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <defs>
        <polygon
          id='path-1'
          points='0 0.65645 42.9998 0.65645 42.9998 15 0 15'
        />
      </defs>
      <g
        id='fob-logos-/-freedom-homes-white'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <g id='Group-28' transform='translate(16.000000, 24.000000)'>
          <polygon
            id='Fill-1'
            fill={color}
            points='7.9002 1.3002 2.9992 23.4992 9.3992 23.4992 11.4002 14.6002 18.2992 14.6002 19.4002 9.8002 12.5002 9.8002 13.1992 6.3992 20.7002 6.3992 21.8002 1.3002'
          />
          <g id='Group-27' transform='translate(0.000000, 0.700000)'>
            <path
              d='M29.0001,5.9002 C28.6001,7.6002 27.7001,7.9002 26.8001,7.9002 L26.1001,7.9002 L26.9001,4.1002 L27.5001,4.1002 C28.7001,4.1002 29.3001,4.5002 29.0001,5.9002 L29.0001,5.9002 Z M32.2001,13.7002 C32.4001,12.7002 32.7001,11.5002 32.4001,10.8002 C32.1001,10.0002 31.3001,9.5002 30.3001,9.4002 C32.1001,9.2002 33.6001,8.0002 34.2001,5.3002 C34.8001,2.4002 33.7001,0.8002 30.2001,0.8002 L22.7001,0.8002 L18.9001,17.9002 L23.9001,17.9002 L25.4001,11.1002 L26.3001,11.1002 C27.2001,11.1002 27.6001,11.4002 27.3001,12.8002 C27.1001,13.6002 27.0001,14.5002 26.8001,15.4002 C26.6001,16.2002 26.4001,17.5002 26.6001,17.9002 L31.9001,17.9002 L32.0001,17.7002 C31.8001,17.6002 31.7001,17.4002 31.6001,17.2002 C31.5001,17.0002 31.6001,16.1002 31.8001,15.2002 L32.2001,13.7002 Z'
              id='Fill-2'
              fill={color}
            />
            <polygon
              id='Fill-4'
              fill={color}
              points='38.9 10.9998 44.3 10.9998 45.1 7.1998 39.7 7.1998 40.3 4.5998 46.1 4.5998 46.9 0.5998 36.1 0.5998 32.3 17.8998 43.3 17.8998 44.2 13.9998 38.2 13.9998'
            />
            <polygon
              id='Fill-6'
              fill={color}
              points='50.7999 10.9998 56.1999 10.9998 56.9999 7.1998 51.5999 7.1998 52.1999 4.5998 57.9999 4.5998 58.8999 0.5998 48.0999 0.5998 44.1999 17.8998 55.2999 17.8998 56.0999 13.9998 50.0999 13.9998'
            />
            <polygon
              id='Fill-8'
              fill={color}
              points='97.4 0.5999 93.4 11.1999 94.2 0.5999 86.9 0.5999 83.1 17.8999 87.8 17.8999 90.6 5.2999 90.7 5.2999 90.1 17.8999 93.7 17.8999 98.7 5.2999 96 17.8999 100.7 17.8999 104.5 0.5999'
            />
            <path
              d='M79.1002,9.3001 C77.9992,14.3001 77.6002,15.1001 76.4992,15.1001 C75.4002,15.1001 75.4002,14.4001 76.4992,9.3001 C77.6002,4.3001 77.9992,3.5001 79.1002,3.5001 C80.1002,3.5001 80.2002,4.2001 79.1002,9.3001 M79.8002,0.3001 C73.3002,0.3001 72.4992,3.9001 71.4002,9.3001 C70.2002,14.8001 69.4002,18.3001 75.9002,18.3001 C82.4002,18.3001 83.1002,14.8001 84.3002,9.3001 C85.4002,3.9001 86.2002,0.3001 79.8002,0.3001'
              id='Fill-10'
              fill={color}
            />
            <path
              d='M66.1998,6.8001 L65.0998,11.8001 C64.8008,13.1001 64.3008,14.4001 63.0998,14.4001 L62.0998,14.4001 L64.3998,4.1001 L65.0998,4.1001 C66.5998,4.1001 66.5998,5.0001 66.1998,6.8001 M71.3008,2.9001 C70.8008,1.5001 69.4998,0.7001 67.3008,0.7001 L60.0998,0.7001 L56.3008,17.9001 L63.5998,17.9001 C68.8008,17.9001 70.0998,13.0001 70.8998,9.3001 C71.6998,5.2001 71.6998,4.2001 71.3008,2.9001'
              id='Fill-12'
              fill={color}
            />
            <g id='Group-16' transform='translate(0.000000, 20.000000)'>
              <mask id='mask-2' fill={color}>
                <use xlinkHref='#path-1' />
              </mask>
              <path
                d='M4.6998,11.4002 L4.8998,12.7992 L3.5998,12.1002 L2.2998,12.7992 L2.5998,11.4002 L1.4998,10.4002 L2.8998,10.2002 L3.5998,8.9002 L4.2998,10.2002 L5.7998,10.4002 L4.6998,11.4002 Z M23.5998,1.5002 L23.7998,2.6002 L22.4998,1.9002 L21.1998,2.6002 L21.3998,1.7002 C19.3998,2.0002 17.4998,2.2992 15.4998,2.7002 L14.8998,3.2002 L15.1998,4.6002 L13.8998,3.9002 L12.5998,4.6002 L12.7998,3.2992 C10.6998,3.7992 8.6998,4.4002 6.8998,5.1002 L7.3998,6.1002 L8.8998,6.2992 L7.7998,7.2992 L7.8998,8.7992 L6.5998,8.1002 L5.2998,8.7992 L5.5998,7.4002 L4.4998,6.4002 L5.8998,6.2002 L6.2998,5.4002 C4.7998,6.0002 3.3998,6.7002 2.0998,7.4002 L-0.0002,15.0002 L2.9998,15.0002 C4.8998,12.6002 7.3998,10.5002 10.3998,8.7992 L10.4998,8.1002 L9.3998,7.1002 L10.8998,6.9002 L11.5998,5.6002 L12.2998,6.9002 L13.6998,7.1002 C14.9998,6.5002 16.3998,5.9002 17.7998,5.4002 L16.7998,4.5002 L18.2998,4.2992 L18.9998,3.0002 L19.6998,4.2992 L20.7998,4.4002 C28.9998,1.7992 37.7998,0.7002 42.9998,0.7002 C38.8998,0.6002 31.3998,0.6002 23.5998,1.5002 L23.5998,1.5002 Z'
                id='Fill-14'
                fill={color}
                mask='url(#mask-2)'
              />
            </g>
            <path
              d='M93.6002,26.7 C95.4002,27.2 96.7002,27.8 96.7002,29.8 C96.7002,32.5 94.2002,33.5 91.9992,33.5 C88.8002,33.5 87.2002,32.2 87.2002,29.5 L89.6002,29.5 C89.4992,31.1 90.6002,31.6 92.1002,31.6 C93.1002,31.6 94.3002,31.1 94.3002,30 C94.3002,28.9 92.8002,28.7 91.4002,28.3 C89.9002,27.9 88.4992,27.2 88.4992,25.3 C88.4992,22.8 90.9002,21.8 93.1002,21.8 C95.4992,21.8 97.4992,22.8 97.4992,25.4 L95.1002,25.4 C95.1002,24.2 94.2002,23.7 92.9992,23.7 C91.9992,23.7 90.9992,24.1 90.9992,25.1 C90.9002,26.2 92.8002,26.5 93.6002,26.7 L93.6002,26.7 Z M86.3002,28.4 L80.4992,28.4 L79.9002,31.2 L86.3002,31.2 L85.8002,33.2 L76.8002,33.2 L79.2002,22.1 L88.1002,22.1 L87.7002,24.2 L81.4002,24.2 L80.9002,26.5 L86.7002,26.5 L86.3002,28.4 Z M72.9002,33.2 L74.9992,24.3 L69.9992,33.2 L67.9992,33.2 L66.9002,24.3 L65.2002,33.2 L62.8002,33.2 L65.3002,22.1 L68.8002,22.1 L69.7002,30.2 L74.2002,22.1 L77.8002,22.1 L75.2002,33.2 L72.9002,33.2 Z M56.9002,33.5 C53.7002,33.5 51.7002,31.7 51.7002,28.6 C51.7002,25 54.1002,21.9 57.9992,21.9 C61.1002,21.9 63.1002,23.6 63.1002,26.7 C63.3002,30.3 60.9992,33.5 56.9002,33.5 L56.9002,33.5 Z M50.2002,20.6 C48.8002,20.6 46.8002,21 44.7002,22.1 L45.1002,22.1 L44.2002,26.4 L48.9002,26.4 L49.8002,22.1 L52.4002,22.1 L49.9002,33.2 L47.3002,33.2 L48.4002,28.4 L43.7002,28.4 L42.6002,33.2 L40.0002,33.2 L42.1002,23.5 C38.9002,25.7 35.6002,29.4 33.7002,35 L96.9992,34.9 L100.2002,20.5 L50.2002,20.6 Z'
              id='Fill-17'
              fill={color}
            />
            <path
              d='M58.1002,23.9002 C55.4992,23.9002 54.3002,26.4002 54.3002,28.6002 C54.3002,30.1002 55.1002,31.5002 56.9002,31.5002 C59.4002,31.5002 60.7002,28.9002 60.7002,26.7992 C60.7002,25.2002 59.9992,23.9002 58.1002,23.9002'
              id='Fill-19'
              fill={color}
            />
            <path
              d='M9.1002,34.9998 L15.6002,34.9998 C21.6002,25.4998 37.0002,20.8998 45.7002,20.6008 C33.8002,20.7998 17.3002,24.1008 9.1002,34.9998'
              id='Fill-21'
              fill={color}
            />
            <path
              d='M45.6998,20.6004 L46.4998,20.6004 L45.6998,20.6004 Z'
              id='Fill-23'
              fill={color}
            />
            <path
              d='M21.2999,34.9998 L28.3999,34.9998 C31.1999,26.8998 40.9999,20.6008 49.3999,20.6008 C37.4999,20.6008 27.8999,25.1998 21.2999,34.9998'
              id='Fill-25'
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FreedomHomes;

import React from 'react';

export default ({ className }) => (
  <svg
    width='5'
    height='5'
    viewBox='0 0 5 5'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <circle cx='2.41638' cy='2.31189' r='1.69861' fill='#91CCEF' />
  </svg>
);

import styled from 'styled-components';

export default styled.div`
  ${({ theme, backgroundColor }) => `
    background-color: ${backgroundColor};

    .ppc-header-content-banner__inner {
      max-width: 37.5rem;
    }

    .ppc-hero-header {
      margin-bottom: 0.9125rem;
      color: ${theme.colors.babyBlue};
    }

    .ppc-hero-sub-header {
      color: ${theme.colors.white};
    }

    .ppc-home-data {
      border-bottom-color: ${theme.colors.babyBlue};

      &__mobile-details {
        border-top-color: ${theme.colors.babyBlue};
      }

      &__line-split line {
        stroke: ${theme.colors.babyBlue};
      }
      
    }
    
  `}
`;

import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Styles from './styles';
import SimpleAnimation from '../../../../styles/Components/SimpleAnimation';
import Picture from '../../../../components/Shared/Picture';

const heroImageSources = [
  { media: '(max-width: 960px)', params: '?w=480' },
  { media: '(max-width: 1150px)', params: '?w=1150' },
  { media: '(min-width: 1150px)', params: '?w=1440' },
  { media: '(min-width: 1440px)', params: '?w=2000' },
];

const HeaderV4 = ({ content, inView }) => {
  return (
    <Styles
      backgroundColor={content?.heroBackgroundColor?.split(':')?.[1]?.trim()}
    >
      <div className='ppc-image-banner'>
        <Picture
          src={content?.heroImage?.fields?.file?.url}
          alt={content?.heroImage?.fields?.description}
          sources={heroImageSources}
          className='ppc-image-banner__desktop'
        />
        <Picture
          src={content?.mobileHeroImage?.fields?.file?.url}
          alt={content?.mobileHeroImage?.fields?.description}
          className='ppc-image-banner__mobile'
        />
      </div>
      <div className='ppc-header-content-banner'>
        <div className='ppc-header-content-banner__inner'>
          <SimpleAnimation.h1
            className='ppc-hero-header ppc-large-heading'
            animateWhen={content.largeHeader && inView}
          >
            {content.largeHeader}
          </SimpleAnimation.h1>
          <SimpleAnimation.p
            className='ppc-hero-intro ppc-paragraph'
            animateWhen={content.intro && inView}
            delay={0.2}
          >
            {content.intro}
          </SimpleAnimation.p>
          <SimpleAnimation.section
            animateWhen={content.disclaimer && inView}
            delay={0.4}
          >
            <ReactMarkdown
              className='ppc-header-disclaimer'
              source={content.disclaimer}
            />
          </SimpleAnimation.section>
        </div>
      </div>
    </Styles>
  );
};

HeaderV4.propTypes = {
  content: PropTypes.object,
};

export default HeaderV4;

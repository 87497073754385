import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Styles from './styles';
import SimpleAnimation from '../../../../styles/Components/SimpleAnimation';

const HeaderV5 = ({ content, inView }) => {
  const addOverlay = true;

  return (
    <Styles
      backgroundColor={content?.heroBackgroundColor?.split(':')?.[1]?.trim()}
      backgroundImage={content?.heroImage?.fields?.file?.url}
      mobileBackgroundImage={content?.mobileHeroImage?.fields?.file?.url}
    >
      <SimpleAnimation.div
        className='ppc-hero-overlay'
        animateWhen={addOverlay}
        slideIn={false}
      />
      <div className='ppc-header-content-banner'>
        <div className='ppc-header-content-banner__inner'>
          <SimpleAnimation.h1
            className='ppc-hero-header ppc-large-heading'
            animateWhen={content.largeHeader && inView}
            delay={0.2}
          >
            {content.largeHeader}
          </SimpleAnimation.h1>
          <SimpleAnimation.p
            className='ppc-hero-intro ppc-paragraph'
            animateWhen={content.intro && inView}
            delay={0.4}
          >
            {content.intro}
          </SimpleAnimation.p>
          <SimpleAnimation.section
            animateWhen={content.disclaimer && inView}
            delay={0.6}
          >
            <ReactMarkdown
              className='ppc-header-disclaimer'
              source={content.disclaimer}
            />
          </SimpleAnimation.section>
        </div>
      </div>
    </Styles>
  );
};

HeaderV5.propTypes = {
  content: PropTypes.object,
  inView: PropTypes.bool,
};

export default HeaderV5;

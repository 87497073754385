import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Number = ({
  label = '',
  error = false,
  className = '',
  touched,
  note = '',
  ...props
}) => {
  const [filled, setFilled] = useState(props.value || touched);
  const handleChange = e => {
    setFilled(true);
    props.onChange(e);
  };

  return (
    <label
      className={`${className} ${touched && error ? 'error' : ''} ${
        filled ? 'filled' : ''
      }`}
      onFocus={() => setFilled(true)}
      onBlur={() => (props.value ? setFilled(true) : setFilled(false))}
    >
      {label && <p className='input-label'>{label}</p>}
      <textarea type='number' {...props} onChange={handleChange} />
      {error && touched && <p className='input-error'>{error}</p>}
      {note && (!error || !touched) && <p className='input-note'>{note}</p>}
    </label>
  );
};

Number.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  note: PropTypes.string,
  touched: PropTypes.bool,
  className: PropTypes.string,
};

export default Number;

import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Styles from './styles';
import Picture from '../../../../components/Shared/Picture';
import SimpleAnimation from '../../../../styles/Components/SimpleAnimation';

const heroImageSources = [
  { media: '(max-width: 960px)', params: '?w=480' },
  { media: '(max-width: 1150px)', params: '?w=1150' },
  { media: '(min-width: 1150px)', params: '?w=1440' },
  { media: '(min-width: 1440px)', params: '?w=2000' },
];

const HeaderV3 = ({ content, inView }) => {
  return (
    <Styles
      backgroundColor={content?.heroBackgroundColor?.split(':')?.[1]?.trim()}
    >
      <div className='ppc-image-banner'>
        <Picture
          src={content?.heroImage?.fields?.file?.url}
          alt={content?.heroImage?.fields?.description}
          sources={heroImageSources}
          className='ppc-image-banner__desktop'
        />
        <Picture
          src={content?.mobileHeroImage?.fields?.file?.url}
          alt={content?.mobileHeroImage?.fields?.description}
          className='ppc-image-banner__mobile'
        />
      </div>
      <div className='ppc-header-content-banner'>
        <div className='ppc-header-content-banner__inner'>
          <SimpleAnimation.h5
            className='ppc-hero-sub-header ppc-h5-sub-header'
            animateWhen={content.subHeader && inView}
            delay={0.2}
          >
            {content.subHeader}
          </SimpleAnimation.h5>
          <SimpleAnimation.h1
            className='ppc-hero-header ppc-large-heading'
            animateWhen={content.largeHeader && inView}
            delay={0.4}
          >
            {content.largeHeader}
          </SimpleAnimation.h1>
          <SimpleAnimation.section
            animateWhen={content.disclaimer && inView}
            delay={0.6}
          >
            <ReactMarkdown
              className='ppc-header-disclaimer'
              source={content.disclaimer}
            />
          </SimpleAnimation.section>
        </div>
      </div>
    </Styles>
  );
};

HeaderV3.propTypes = {
  content: PropTypes.object,
};

export default HeaderV3;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const Date = ({
  label = '',
  error = false,
  touched = false,
  setFieldValue,
  value,
  name,
  className = '',
  ...props
}) => {
  const [filled, setFilled] = useState(false);
  const handleChange = date => {
    setFilled(true);
    setFieldValue(name, date);
  };

  return (
    <label
      className={`${className} ${touched && error ? 'error' : ''} ${
        filled ? 'filled' : ''
      }`}
      onFocus={() => {
        setFilled(true);
      }}
      onBlur={() => {
        value ? setFilled(true) : setFilled(false);
      }}
    >
      {label && <p className='input-label'>{label}</p>}
      <DatePicker {...props} onChange={handleChange} selected={value} />
      <p className='input-error'>{touched && error}</p>
    </label>
  );
};

Date.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Date;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

const Text = ({
  label = '',
  error = false,
  touched = false,
  className = '',
  decimalPlaces = -1,
  ...props
}) => {
  const [filled, setFilled] = useState(props.value || touched);
  const handleChange = e => {
    const [value, decimal] = e.value.split('.');
    const newValue =
      decimalPlaces !== -1
        ? decimalPlaces > 0
          ? `${value}${
              decimal ? `.${decimal.substring(0, decimalPlaces)}` : ''
            }`
          : value
        : e.value;
    const newEvent = {
      ...e,
      target: {
        ...e.target,
        value: newValue,
      },
      value: newValue,
    };

    setFilled(true);
    props.onChange(newEvent);
  };

  return (
    <label
      className={`${className} ${touched && error ? 'error' : ''} ${
        filled ? 'filled' : ''
      }`}
      onFocus={() => setFilled(true)}
      onBlur={() => (props.value ? setFilled(true) : setFilled(false))}
    >
      {label && <p className='input-label'>{label}</p>}
      <Input type='number' {...props} onChange={handleChange} />
      <p className='input-error'>{touched && error}</p>
    </label>
  );
};

Text.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Text;

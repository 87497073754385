import styled from 'styled-components';

export default styled.div`
  ${({ theme, backgroundColor }) => `
    background-color: ${backgroundColor};

    .ppc-header-content-banner__inner {
      max-width: 39.0625rem;
    }

    .ppc-hero-header {
      color: ${theme.colors.babyBlue};
      margin-bottom: 0.9125rem;
      margin-top: 1.44875rem;
    }

    .ppc-hero-sub-header {
      color: ${theme.colors.white};
      margin-bottom: 0.9125rem;
    }

    .ppc-header-secondary-header {
      color: ${theme.colors.yellow};
    }
    
  `}
`;

import React from 'react';

const ClaytonHomes = ({ color = '#868F97', ...props }) => {
  return (
    <svg
      width='136px'
      height='83px'
      viewBox='0 0 136 83'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g
        transform='translate(14.817308, 17.000000)'
        fill={color}
        fillRule='nonzero'
      >
        <polygon points='64.3973077 0 35.868514 11.0532692 35.868514 13.1066434 64.3973077 7.0338986 92.9261014 13.1066434 92.9261014 11.0532692' />
        <g transform='translate(0.000000, 10.485315)'>
          <path d='M106.032745,20.7958741 C105.246346,20.7958741 104.591014,21.3638287 104.591014,22.2376049 C104.591014,23.0676923 105.202657,23.6793357 106.032745,23.6793357 C106.819143,23.6793357 107.474476,23.1113811 107.474476,22.2376049 C107.474476,21.3638287 106.819143,20.7958741 106.032745,20.7958741 Z M106.032745,23.4608916 C105.333724,23.4608916 104.896836,22.9366259 104.896836,22.2376049 C104.896836,21.5385839 105.377413,21.0143182 106.032745,21.0143182 C106.644388,21.0143182 107.168654,21.4948951 107.168654,22.2376049 C107.168654,22.9803147 106.644388,23.4608916 106.032745,23.4608916 Z' />
          <path d='M106.688077,21.9317832 C106.688077,21.6259615 106.469633,21.4512063 106.076434,21.4512063 L105.46479,21.4512063 L105.46479,23.0676923 L105.726923,23.0676923 L105.726923,22.3686713 L105.989056,22.3686713 L106.425944,23.0676923 L106.731766,23.0676923 L106.294878,22.3686713 C106.513322,22.3249825 106.688077,22.2376049 106.688077,21.9317832 Z M105.726923,22.1502273 L105.726923,21.6696503 L106.032745,21.6696503 C106.2075,21.6696503 106.382255,21.7133392 106.382255,21.8880944 C106.382255,22.1065385 106.2075,22.1502273 105.989056,22.1502273 L105.726923,22.1502273 Z' />
          <path d='M11.2280245,5.37372378 C13.7182867,5.37372378 15.509528,6.59701049 15.8153497,8.86882867 L21.757028,8.86882867 C21.5822727,3.40772727 16.2522378,0.305821678 11.3590909,0.305821678 C4.49994755,0.305821678 0,5.54847902 0,12.1454895 C0,18.7425 4.45625874,23.9851573 11.3590909,23.9851573 C17.126014,23.9851573 21.4075175,20.5337413 21.757028,14.6794406 L15.8153497,14.6794406 C15.5969056,17.3007692 14.0241084,18.8735664 11.2280245,18.8735664 C7.38340909,18.8735664 5.94167832,15.509528 5.94167832,12.1018007 C5.94167832,8.78145105 7.38340909,5.37372378 11.2280245,5.37372378 Z' />
          <rect
            x={22.456049}
            y={0.74270979}
            width={5.41741259}
            height={22.7181818}
          />
          <path d='M44.649965,12.0581119 C44.649965,7.38340909 40.8490385,6.50963287 36.9607343,6.50963287 C35.0821154,6.50963287 33.1598077,6.81545455 31.7180769,7.64554196 C30.2763462,8.51931818 29.2278147,9.91736014 29.1404371,12.0581119 L34.2083392,12.0581119 C34.470472,10.6600699 35.4316259,9.96104895 36.9170455,9.96104895 C38.0529545,9.96104895 39.5383741,10.4416259 39.5383741,11.6649126 C39.5383741,12.6697552 39.0141084,13.0192657 38.0966434,13.194021 C34.3394056,13.9367308 28.4851049,13.5435315 28.4851049,18.9172552 C28.4851049,22.3249825 30.9753671,23.9414685 34.1209615,23.9414685 C36.086958,23.9414685 38.1403322,23.373514 39.5820629,21.8880944 C39.6257517,22.4123601 39.7131294,22.9366259 39.8878846,23.4608916 L45.348986,23.4608916 C44.6936538,22.1502273 44.6936538,20.6211189 44.6936538,19.2230769 L44.6936538,12.0581119 L44.649965,12.0581119 Z M36.1306469,20.6211189 C34.9073601,20.6211189 33.8588287,20.0968531 33.8588287,18.7425 C33.8588287,17.4318357 34.8636713,16.9075699 36.086958,16.6454371 C37.2665559,16.3396154 38.6645979,16.2522378 39.4073077,15.7716608 C39.5383741,19.1793881 38.7082867,20.6211189 36.1306469,20.6211189 Z' />
          <path d='M61.775979,10.4853147 L61.775979,17.825035 C61.775979,22.3249825 62.9555769,23.6356469 67.5865909,23.6356469 C68.5914336,23.6356469 69.5525874,23.5045804 70.5574301,23.4608916 L70.5574301,19.4852098 C70.0768531,19.4852098 69.5962762,19.5288986 69.1593881,19.5288986 C67.9797902,19.5288986 67.1933916,19.2667657 67.1933916,17.9561014 L67.1933916,10.4853147 L70.5574301,10.4853147 L70.5574301,6.99020979 L67.1933916,6.99020979 L67.1933916,1.9659965 L61.775979,1.9659965 L61.775979,6.99020979 L61.3827797,6.99020979 L59.0235839,6.99020979 L55.8343007,6.99020979 L52.7323951,17.7813462 L52.6887063,17.7813462 L49.5431119,6.99020979 L43.8198776,6.99020979 L48.1013811,18.4803671 C48.4072028,19.3541434 49.4557343,21.9317832 49.4557343,22.8055594 C49.4557343,23.9414685 48.5382692,24.6404895 47.3149825,24.6404895 L45.4363636,24.6404895 L45.4363636,29.0530594 L47.9703147,29.0530594 C51.6401748,29.0530594 53.5624825,28.6161713 54.8731469,24.99 L60.1158042,10.4853147 L61.775979,10.4853147 Z' />
          <path d='M78.4651049,23.9851573 C83.5766958,23.9851573 87.2028671,20.2279196 87.2028671,15.2473951 C87.2028671,10.2668706 83.6203846,6.46594406 78.4651049,6.46594406 C73.353514,6.46594406 69.7710315,10.2668706 69.7710315,15.2473951 C69.7710315,20.2279196 73.353514,23.9851573 78.4651049,23.9851573 Z M78.4651049,10.6163811 C80.9990559,10.6163811 81.7854545,13.1503322 81.7854545,15.2473951 C81.7854545,17.344458 80.9990559,19.8347203 78.4651049,19.8347203 C75.9311538,19.8347203 75.1884441,17.344458 75.1884441,15.2473951 C75.1884441,13.1503322 75.9748427,10.6163811 78.4651049,10.6163811 Z' />
          <path d='M93.0571678,14.5046853 C93.0571678,12.6697552 93.9309441,10.9658916 95.9843182,10.9658916 C98.9551573,10.9658916 98.6930245,13.2377098 98.6930245,15.509528 L98.6930245,23.5045804 L104.110437,23.5045804 L104.110437,12.8881993 C104.110437,10.4853147 103.673549,6.46594406 98.1687587,6.46594406 C96.2027622,6.46594406 93.9746329,7.51447552 92.9697902,9.21833916 L92.9261014,9.21833916 L92.9261014,6.99020979 L87.6834441,6.99020979 L87.6834441,23.5045804 L93.0571678,23.5045804 L93.0571678,14.5046853 Z' />
          <path d='M62.5186888,28.703549 C61.6449126,28.703549 60.6837587,29.1404371 60.0721154,30.1015909 L60.0284266,30.1015909 L60.0284266,25.7763986 L57.7566084,25.7763986 L57.7566084,37.2665559 L60.0284266,37.2665559 L60.0284266,32.8976748 C60.0284266,31.1938112 60.5526923,30.4947902 61.8196678,30.4947902 C62.8681993,30.4947902 63.3050874,31.1501224 63.3050874,32.5481643 L63.3050874,37.2665559 L65.5769056,37.2665559 L65.5769056,32.154965 C65.5332168,30.0579021 64.9215734,28.703549 62.5186888,28.703549 Z' />
          <path d='M70.2952972,28.703549 C67.6739685,28.703549 66.0137937,30.4511014 66.0137937,33.1161189 C66.0137937,35.7374476 67.7176573,37.485 70.2952972,37.485 C72.9166259,37.485 74.6204895,35.7374476 74.6204895,33.1161189 C74.5768007,30.4511014 72.9166259,28.703549 70.2952972,28.703549 Z M70.2952972,35.7374476 C68.7661888,35.7374476 68.2856119,34.3830944 68.2856119,33.0724301 C68.2856119,31.7180769 68.7661888,30.4074126 70.2952972,30.4074126 C71.8680944,30.4074126 72.3049825,31.7617657 72.3049825,33.0724301 C72.3049825,34.4267832 71.8244056,35.7374476 70.2952972,35.7374476 Z' />
          <path d='M84.6689161,28.703549 C83.533007,28.703549 82.6592308,29.3588811 82.1786538,30.1015909 C81.7417657,29.0967483 80.7806119,28.703549 79.7320804,28.703549 C78.6398601,28.703549 77.7660839,29.1841259 77.1981294,30.0579021 L77.1544406,30.0579021 L77.1544406,28.921993 L75.0136888,28.921993 L75.0136888,37.2228671 L77.285507,37.2228671 L77.285507,32.4170979 C77.285507,30.8006119 78.3777273,30.4947902 78.8146154,30.4947902 C80.2563462,30.4947902 80.2126573,31.5870105 80.2126573,32.5481643 L80.2126573,37.2228671 L82.4844755,37.2228671 L82.4844755,32.5918531 C82.4844755,31.5433217 82.7466084,30.4947902 84.0135839,30.4947902 C85.3242483,30.4947902 85.4116259,31.4122552 85.4116259,32.5044755 L85.4116259,37.2228671 L87.6834441,37.2228671 L87.6834441,31.6743881 C87.7271329,29.5336364 86.4601573,28.703549 84.6689161,28.703549 Z' />
          <path d='M92.4018357,28.703549 C89.8678846,28.703549 88.164021,30.5821678 88.164021,33.1161189 C88.164021,35.6937587 89.780507,37.485 92.4018357,37.485 C94.2804545,37.485 95.6348077,36.6549126 96.2901399,34.6889161 L94.2804545,34.6889161 C94.1493881,35.2131818 93.4066783,35.7811364 92.4892133,35.7811364 C91.2222378,35.7811364 90.5232168,35.1258042 90.4358392,33.6840734 L96.4212063,33.6840734 C96.5959615,31.1064336 95.2416084,28.703549 92.4018357,28.703549 Z M90.4358392,32.1986538 C90.479528,31.5433217 90.8727273,30.4074126 92.314458,30.4074126 C93.4066783,30.4074126 93.9309441,31.0190559 94.1493881,32.1986538 L90.4358392,32.1986538 Z' />
          <path d='M101.751241,32.2423427 C100.440577,31.936521 99.1736014,31.8054545 99.1736014,31.0190559 C99.1736014,30.3637238 99.9163112,30.2326573 100.440577,30.2326573 C101.270664,30.2326573 101.925997,30.4947902 102.013374,31.3685664 L104.197815,31.3685664 C104.023059,29.3151923 102.362885,28.703549 100.527955,28.703549 C98.7367133,28.703549 96.9017832,29.2715035 96.9017832,31.3685664 C96.9017832,32.853986 98.2124476,33.2908741 99.5231119,33.5966958 C101.183287,33.9462063 102.100752,34.2083392 102.100752,34.8636713 C102.100752,35.6500699 101.270664,35.9122028 100.615332,35.9122028 C99.6978671,35.9122028 98.8677797,35.5190035 98.8677797,34.5141608 L96.6833392,34.5141608 C96.7707168,36.6986014 98.6493357,37.4413112 100.571643,37.4413112 C102.450262,37.4413112 104.37257,36.7422902 104.37257,34.5578497 C104.37257,33.0287413 103.061906,32.5481643 101.751241,32.2423427 Z' />
        </g>
      </g>
    </svg>
  );
};

export default ClaytonHomes;

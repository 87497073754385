import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Styles from './styles';
import Picture from '../../../../components/Shared/Picture';
import VerticalLine from '../../../../components/Shared/Icons/VerticalLine';
import Bullet from '../../../../components/Shared/Icons/Bullet';
import SimpleAnimation from '../../../../styles/Components/SimpleAnimation';

const heroImageSources = [
  { media: '(max-width: 960px)', params: '?w=480' },
  { media: '(max-width: 1150px)', params: '?w=1150' },
  { media: '(min-width: 1150px)', params: '?w=1440' },
  { media: '(min-width: 1440px)', params: '?w=2000' },
];

const HeaderV1 = ({ content, homeData, inView }) => {
  const hasValidHomeData = useMemo(
    () =>
      typeof homeData?.baths === 'number' &&
      typeof homeData?.beds === 'number' &&
      homeData?.modelDescription &&
      typeof homeData?.maxSquareFeet === 'number',
    [homeData]
  );

  return (
    <Styles
      backgroundColor={content?.heroBackgroundColor?.split(':')?.[1]?.trim()}
    >
      {content?.heroImage?.fields?.file?.url && (
        <div className='ppc-image-banner'>
          <Picture
            src={content?.heroImage?.fields?.file?.url}
            alt={content?.heroImage?.fields?.description}
            sources={heroImageSources}
            className='ppc-image-banner__desktop'
          />
          <Picture
            src={content?.mobileHeroImage?.fields?.file?.url}
            alt={content?.mobileHeroImage?.fields?.description}
            className='ppc-image-banner__mobile'
          />
        </div>
      )}
      <div className='ppc-header-content-banner'>
        <div className='ppc-header-content-banner__inner'>
          <>
            <SimpleAnimation.div
              className='ppc-home-data'
              animateWhen={hasValidHomeData && inView}
              delay={0.2}
            >
              <h5>{homeData.modelDescription}</h5>
              <VerticalLine className='ppc-home-data__line-split' />
              <h5>
                {parseInt(homeData.maxSquareFeet).toLocaleString()} sq. ft.
              </h5>
              <Bullet className='ppc-home-data__bullet' />
              <h5>{homeData.beds} beds</h5>
              <Bullet className='ppc-home-data__bullet' />
              <h5>{homeData.baths} baths</h5>
            </SimpleAnimation.div>
            <SimpleAnimation.div
              className='ppc-home-data ppc-home-data__mobile'
              animateWhen={hasValidHomeData && inView}
              delay={0.2}
            >
              <h5>{homeData.modelDescription}</h5>
              <div className='ppc-home-data__mobile-details'>
                <h5>
                  {parseInt(homeData.maxSquareFeet).toLocaleString()} sq. ft.
                </h5>
                <Bullet className='ppc-home-data__bullet' />
                <h5>{homeData.beds} beds</h5>
                <Bullet className='ppc-home-data__bullet' />
                <h5>{homeData.baths} baths</h5>
              </div>
            </SimpleAnimation.div>
          </>

          <SimpleAnimation.h1
            className='ppc-hero-header ppc-h1-heading'
            animateWhen={content.header && inView}
            delay={0.4}
          >
            {content.header}
          </SimpleAnimation.h1>

          <SimpleAnimation.h5
            className='ppc-hero-sub-header ppc-h5-sub-header'
            animateWhen={content.subHeader && inView}
            delay={0.6}
          >
            {content.subHeader}
          </SimpleAnimation.h5>

          <SimpleAnimation.section animateWhen={content.disclaimer} delay={0.8}>
            <ReactMarkdown
              className='ppc-header-disclaimer'
              source={content.disclaimer}
            />
          </SimpleAnimation.section>
        </div>
      </div>
    </Styles>
  );
};

HeaderV1.propTypes = {
  content: PropTypes.object,
};

export default HeaderV1;

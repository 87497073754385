import styled from 'styled-components';

export default styled.section`${({ theme }) => `
  display: flex;
  justify-content: space-between;
  min-height: 65vh;
  width: 100%;
  max-width: ${theme.maxWidths.header};
  margin: auto;

  h1, p {
    padding: 0;
    margin: 0;
  }

  .thank-you-content {
    max-width: 31.898125rem;
    padding: 5.425rem 7.351875rem;
  }

  .thank-you-image-container {
    height: 39.8125rem;
    width: 50%;
    position: relative;

    picture {
      width: 100%;
      height: 100%;
      position: absolute;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      
    }
  }

  .thank-you-content-heading {
    font-size: ${theme.type.large};
    font-family: ${theme.fonts.secondary};
    font-weight: 800;
    line-height: 64px;
    letter-spacing: -1.25px;
    color: ${theme.colors.darkBlue};
  }

  .thank-you-content-intro {
    margin-top: 2.325rem;
  }

  .thank-you-content-buttons {
    margin-top: 2.15rem;
    flex-direction: row;
    
    button, a {
      display: inline-flex;
      align-items: center;
      box-sizing: border-box;
      padding: 1rem 1.375rem;
      font-family: ${theme.fonts.primary};
      font-weight: 700;
      font-size: ${theme.type.paragraph.default};
      line-height: 19px;

      &:first-of-type {
        margin-right: 1.456875rem;
      }
      
    }

  }

  @media (max-width: ${theme.breakpoints.desktopMed}) {
    min-height: 30rem;
    
    .thank-you-content {
      width: 100%;
      padding: 4.1875rem 6.25rem;

      p {
        max-width: initial;
      }
      
    }
    
    .thank-you-image-container {
      display: none;
    }

  }

  @media (max-width: ${theme.breakpoints.tablet}) {
  
    .thank-you-content-heading {
      font-size: ${theme.type.largeMobile};
    }
  
    .thank-you-content {
      padding: 4.1875rem ${theme.padding.inner};
    }

    .thank-you-content-buttons {
      flex-direction: column;

      button, a {
        width: 100%;
        justify-content: center;

        &:first-of-type {
          margin-bottom: 1.456875rem;
        }
        
      }
      
    }
  
  }
  
`}`;

import styled from 'styled-components';

export default styled.section`
  .ppc-disclaimer {
    line-height: 14px;
    font-weight: 400;
    padding-top: 2.3125rem;
    padding-bottom: 2.3125rem;
  }
`;

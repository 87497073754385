import React, { useMemo } from 'react';
import Styles from './HomePartners.styled';
import Logos from '../../components/Shared/Icons/Partners';
import theme from '../../styles/theme';
import InView from '../Shared/InView';
import SimpleAnimation from '../../styles/Components/SimpleAnimation';

const HomePartners = ({
  backgroundColor = 'Grey-6: #F3F5F7',
  className = 'home-partners',
}) => {
  const colors = useMemo(() => {
    const possibleColors = {
      'Grey-6: #F3F5F7': {
        textColor: theme.colors.darkBlue,
        backgroundColor: theme.colors.grey6,
        icon: {
          primary: '#868F97',
          secondary: '#FFF',
          tertiary: '#868F97',
        },
      },
      'VMF Blue: #004B8C': {
        textColor: theme.colors.white,
        backgroundColor: theme.colors.vmfBlue,
        icon: {
          primary: '#FFF',
          secondary: null,
          tertiary: '#FFF',
        },
      },
    };

    return (
      possibleColors?.[backgroundColor] || possibleColors['Grey-6: #F3F5F7']
    );
  }, [backgroundColor]);

  return (
    <InView>
      <Styles colors={colors} className={className}>
        <SimpleAnimation.h2 slideDirection='up'>
          Our Home Partners
        </SimpleAnimation.h2>
        <SimpleAnimation.div
          className='partners-list'
          delay={0.4}
          slideDirection='up'
        >
          <div className='partner-row partner-row__large'>
            <div className='partner-row partner-row__double'>
              <div className='partner-icon'>
                <Logos.ClaytonHomes color={colors.icon.primary} />
              </div>
              <div className='partner-icon'>
                <Logos.CrosslandHomes color={colors.icon.primary} />
              </div>
            </div>
            <div className='partner-row partner-row__double'>
              <div className='partner-icon'>
                <Logos.FreedomHomes
                  color={colors.icon.primary}
                  backgroundColor={colors.icon.secondary}
                />
              </div>
              <div className='partner-icon'>
                <Logos.InternationalHomes color={colors.icon.primary} />
              </div>
            </div>
          </div>
          <div className='partner-row partner-row__large'>
            <div className='partner-row partner-row__double'>
              <div className='partner-icon'>
                <Logos.LuvHomes
                  color={colors.icon.primary}
                  backgroundColor={colors.icon.tertiary}
                />
              </div>
              <div className='partner-icon'>
                <Logos.OakwoodHomes color={colors.icon.primary} />
              </div>
            </div>
            <div className='partner-row partner-row__single'>
              <div className='partner-icon partner-icon--no-margin'>
                <Logos.TruValue color={colors.icon.primary} />
              </div>
            </div>
          </div>
        </SimpleAnimation.div>
      </Styles>
    </InView>
  );
};

export default HomePartners;

import React, { useEffect, useMemo, useState } from 'react';
import Styles from './styles';
import ButtonLink from '../../../../components/Shared/ButtonLink';
import Button from '../../../../components/Shared/Button';
import Input from '../../../../components/Shared/Input';
import ReactMarkdown from 'react-markdown';
import getQueryParams from '../../../../util/getQueryParams';
import submitMarketingForm from '../../../../services/submitMarketingForm';
import createQueryString from '../../../../util/url/createQueryString';
import { useHistory } from 'react-router';
import noop from '../../../../util/noop';
import CustomUrl from '../../../../util/url/CustomUrl';

const orderFormFields = fields => {
  const order = {
    Email: 2,
    'First Name': 0,
    'Last Name': 1,
    'ZIP Code': 3,
    'Phone Number': 4,
  };

  const sortedOutput = new Array(fields.length);
  Object.keys(order).forEach(orderKey => {
    const orderIndex = order[orderKey];
    if (orderIndex !== undefined && fields.some(field => field === orderKey)) {
      sortedOutput[orderIndex] = orderKey;
    }
  });

  return sortedOutput;
};

const configureFormFields = fields => {
  const sortedFields = [...orderFormFields(fields)];

  const onBeforePhoneChange = value => {
    const newValue = value.replace(/[^0-9\-]/gim, '');

    const numbersOnly = newValue.replace(/\-/gim, '');
    const split = numbersOnly.split('');
    if (numbersOnly.length >= 3 && newValue.length > 3) split.splice(3, 0, '-');
    if (numbersOnly.length >= 6 && newValue.length > 7) split.splice(7, 0, '-');

    if (numbersOnly.length < 11) return split.join('');
  };

  const configuration = {
    Email: {
      name: 'Email',
      parameterName: 'emailAddress',
      required: true,
      format: /^\S{1,}@\S{1,}\.\S{1,}$/gim,
      type: 'text',
      placeholder: 'Email',
      className: 'ppc-header-form-input ppc-header-form-input__email',
      min: 5,
      max: 100,
    },
    'First Name': {
      name: 'First Name',
      parameterName: 'firstName',
      required: true,
      format: null,
      type: 'text',
      placeholder: 'First Name',
      className: 'ppc-header-form-input ppc-header-form-input__first-name',
      min: 1,
      max: 100,
    },
    'Last Name': {
      name: 'Last Name',
      parameterName: 'lastName',
      required: true,
      format: null,
      type: 'text',
      placeholder: 'Last Name',
      className: 'ppc-header-form-input ppc-header-form-input__last-name',
      min: 1,
      max: 100,
    },
    'ZIP Code': {
      name: 'ZIP Code',
      parameterName: 'zipCode',
      required: true,
      format: /[a-z0-9\-]/gim,
      type: 'text',
      placeholder: 'Zip Code',
      className: 'ppc-header-form-input ppc-header-form-input__zip-code',
      min: 1,
      max: 20,
    },
    'Phone Number': {
      name: 'Phone Number',
      parameterName: 'phoneNumber',
      required: true,
      format: /[0-9\-]/,
      type: 'text',
      placeholder: 'Phone Number',
      className: 'ppc-header-form-input ppc-header-form-input__email',
      min: 1,
      max: 12,
      onBeforeChange: onBeforePhoneChange,
    },
  };

  return sortedFields
    .map(field => configuration[field] || null)
    .filter(config => config);
};

const Form = ({
  webForm = {},
  imageAlt = '',
  imageSrc = '',
  className = 'ppc-web-form',
  isSubmittingForm = false,
  onSubmittingFormChange = noop,
  triggerGTMEvent = () => noop,
}) => {
  const history = useHistory(null);
  const [inputs, updateInputs] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const showHeaderButton = useMemo(
    () => webForm?.buttonAndTextColors && webForm?.firstFormButtonText,
    [webForm]
  );

  const inputConfigurations = useMemo(
    () => configureFormFields(webForm?.formContactFields),
    [webForm?.formContactFields]
  );

  useEffect(() => {
    if (
      Object.keys(inputConfigurations).length > 0 &&
      Object.keys(inputs).length === 0
    ) {
      const defaultInputValues = Object.values(inputConfigurations).reduce(
        (defaultValue, configuration) => {
          if (!defaultValue[configuration.name]) {
            defaultValue[configuration.name] = {
              value: '',
              touched: true,
              error: '',
              configuration,
            };
          }

          return defaultValue;
        },
        {}
      );

      updateInputs(defaultInputValues);
    }
  }, [inputConfigurations]);

  const handleInputChange = input => {
    updateInputs(previousState => ({
      ...previousState,
      [input.configuration.name]: {
        ...previousState[input.configuration.name],
        value: input.value,
        touched: false,
      },
    }));
  };

  const validateInputs = () => {
    let hasError = false;
    const newInputs = { ...inputs };

    const addError = (key, errorMsg) => {
      newInputs[key].error = errorMsg;
      newInputs[key].touched = true;
      if (!hasError) hasError = true;
    };

    Object.keys(inputs).forEach(key => {
      const input = inputs[key];
      const { value, configuration: config } = input;

      if (config.required && !value)
        addError(key, `${config.name} is required.`);
      if (typeof config.max === 'number' && value.length > config.max)
        addError(key, `${config.name} is too long.`);
      if (typeof config.min === 'number' && value.length < config.min)
        addError(key, `${config.name} is too short.`);
      if (config.format && !value.match(config.format))
        addError(key, `Invalid ${config.name} format.`);
    });

    updateInputs(newInputs);

    return !hasError;
  };

  const submitForm = (baseUrl, action) => {
    const requireValidation = action !== 'apply';
    const shouldSubmitMarketingForm = action !== 'apply';

    const validFormData = requireValidation ? validateInputs() : true;

    if (validFormData) {
      const queryParams = getQueryParams(window.location.search);
      const tracker = window?.ga?.getAll ? window.ga.getAll()?.[0] : false;
      const values = Object.values(inputs).reduce(
        (newValues, input) => {
          newValues[input.configuration.parameterName] = input.value;
          return newValues;
        },
        {
          originWebApp: webForm?.originWebApp,
          originWebPageType: webForm?.originWebPageType,
          originWebFormType: webForm?.originWebFormType,
          originCampaign: webForm?.originCampaign,
          originWebFormCategory: webForm?.originWebFormCategory,
          dataExtension: webForm?.dataExtension,
          canEmail: true,
          doNotCall: false,
          originWebUrl: window.location.href,
          originWebAppType: '',
          utmSource: queryParams?.utm_source || '',
          utmCampaign: queryParams?.utm_campaign || '',
          utmTerm: queryParams?.utm_term || '',
          utmContent: queryParams?.utm_content || '',
          utmMedium: queryParams?.utm_medium || '',
          kenshooId: queryParams?.kenshooid || '',
          gaClientId: tracker ? tracker?.get('clientId') : '',
          gaClickId: queryParams?.gclid || '',
          fbClickId: queryParams?.fbclid || '',
          marketingSource: 0,
        }
      );

      const navigate = () => {
        const [url, query] = baseUrl.split('?');
        const newQueryParams = {
          utm_source: values.utmSource,
          utm_campaign: values.utmCampaign,
          utm_term: values.utmTerm,
          utm_content: values.utmContent,
          utm_medium: values.utmMedium,
        };

        const fullUrl = new CustomUrl(baseUrl);
        fullUrl.joinQuery(newQueryParams, window.location.search);

        if (values?.Source === 'PPC_VMF_Search') {
          fullUrl.addQueryParam('Source', 'PPC_VMF_Search');
        }

        if (url.slice(0, 9) === '/applynow') {
          window.location.href = fullUrl.fullUrl;
        } else {
          history.push(fullUrl.fullUrl);
        }
      };

      triggerGTMEvent({
        name: 'vmf.cta_click',
        location: 'dual CTA form module',
        category: action === 'apply' ? 'loan_application' : 'email_subscribe',
        action: 'form',
        label: action === 'apply' ? 'apply' : 'get_info_form_submit',
      })();

      if (shouldSubmitMarketingForm) {
        onSubmittingFormChange(true);
        setErrorMessage('');

        submitMarketingForm(values)
          .then(response => {
            onSubmittingFormChange(false);

            if (response?.status === 200) {
              navigate();
            } else {
              throw reponse?.status;
            }
          })
          .catch(err => {
            onSubmittingFormChange(false);
            setErrorMessage(err);
            console.error(err);
          });
      } else {
        navigate();
      }
    }
  };

  const handleFormSubmit = e => e.preventDefault();

  const handleFirstButtonClick = baseUrl => e => {
    e.preventDefault();
    if (!isSubmittingForm && !errorMessage) {
      submitForm(baseUrl, 'apply');
    }
  };

  const handleSecondButtonClick = baseUrl => e => {
    e.preventDefault();
    if (!isSubmittingForm && !errorMessage) {
      submitForm(baseUrl, 'info');
    }
  };

  return (
    <Styles
      imageSrc={imageSrc}
      className={className}
      isSubmittingForm={isSubmittingForm}
    >
      <div className='ppc-header-form'>
        <div className='ppc-header-form__heading'>
          {webForm?.formHeader && (
            <h4 className='ppc-header-form__header'>{webForm?.formHeader}</h4>
          )}
          {showHeaderButton && (
            <div className='ppc-header-form__button-container'>
              <ButtonLink
                className='ppc-header-form__first-button'
                contentfulColors={webForm?.buttonAndTextColors}
                size='large'
                to={webForm?.firstFormButtonURL}
                onClick={handleFirstButtonClick(webForm?.firstFormButtonURL)}
                disabled={isSubmittingForm || !!errorMessage}
              >
                {webForm?.firstFormButtonText}
              </ButtonLink>
            </div>
          )}
        </div>
        <div className='ppc-header-form__body'>
          {errorMessage ? (
            <p className='ppc-header-form__error type-h4'>
              Uh oh, looks like we've encountered an error. Please try again
              later.
            </p>
          ) : (
            <>
              {webForm?.formSubHeader && (
                <h6 className='ppc-header-form__sub-header'>
                  {webForm.formSubHeader}
                </h6>
              )}
              {webForm?.formIntro && (
                <p className='ppc-header-form__form-intro'>
                  {webForm.formIntro}
                </p>
              )}
              <form
                onSubmit={handleFormSubmit}
                className='ppc-header-form__form-body'
              >
                {inputConfigurations.length > 0 &&
                  Object.keys(inputs).length > 0 &&
                  inputConfigurations.map(({ name }, index) => {
                    const input = inputs[name];

                    if (input) {
                      const onChange = e => {
                        const beforeChange = input.configuration.onBeforeChange
                          ? input.configuration.onBeforeChange(e.target.value)
                          : e.target.value;

                        const newValue = {
                          ...input,
                          value: beforeChange,
                        };

                        handleInputChange(newValue);
                      };

                      return (
                        <Input
                          className={input.configuration.className}
                          key={`ppc-form-input-${index}`}
                          type={input.configuration.type}
                          placeholder={input.configuration.placeholder}
                          touched={input.touched}
                          error={input.error}
                          value={input.value}
                          onChange={onChange}
                          maxLength={input.configuration.max}
                          minLength={input.configuration.min}
                          disabled={isSubmittingForm}
                        />
                      );
                    }
                  })}
                {webForm?.disclaimer && (
                  <div className='ppc-header-form__disclaimer'>
                    <ReactMarkdown source={webForm.disclaimer} />
                  </div>
                )}
                {webForm?.secondFormButtonText && (
                  <div className='ppc-header-form__form-buttons'>
                    <ButtonLink
                      contentfulColors={
                        webForm.secondButtonColor === 'Inverse colors'
                          ? webForm.secondButtonColor
                          : webForm.firstButtonColor
                      }
                      inverse={webForm.secondButtonColor === 'Inverse colors'}
                      className='ppc-header-form__second-button'
                      onClick={handleSecondButtonClick(
                        webForm?.secondFormButtonURL
                      )}
                      to={webForm?.secondButtonURL}
                    >
                      {webForm.secondFormButtonText}
                    </ButtonLink>
                  </div>
                )}
              </form>
            </>
          )}
        </div>
      </div>
    </Styles>
  );
};

export default Form;

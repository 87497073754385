import styled from 'styled-components';

export default styled.section`${({ theme, colors }) => `
  box-sizing: border-box;
  padding: 3rem ${theme.padding.inner} 2.325rem ${theme.padding.inner};
  text-align: center;
  background-color: ${colors.background};
  color: ${colors.text};

  h2, p {
    margin: 0;
  }

  .snippet-section {

    &__heading {
      margin-bottom: 2.8rem;
      color: ${colors.heading};
    }

    &__snippets {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
    }

    &__snippet {
      margin-bottom: 2.9rem;
      max-width: 24.5rem;

      &__header {
        font-size: 1rem;
        margin: 0;
        margin-bottom: 1.2rem;
      }

      img {
        margin-bottom: 1.0506rem;
        max-width: 6.4375rem;
        max-height: 4.9375rem;
      }

      p.snippet-text {
        letter-spacing: 0;

        ul {
          text-align: center;
          list-style-position: inside;

          p {
            display: inline;
          }
          
        }
        
      }

      &:last-of-type {
        margin-bottom: 0;
        margin-right: 0;
      }
    }

  }

  @media (min-width: ${theme.breakpoints.desktop}) {

    .snippet-section {

      &__snippets {
        flex-direction: row;
        max-width: 73.2706rem;
        margin: auto;
        align-items: flex-start;
      }

      &__snippet {
        margin-bottom: 0;
        margin-right: 1.6994rem;
      }

    }

  }
  
`}`;

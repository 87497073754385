import React from 'react';

export default ({ className = '' }) => (
  <svg
    width='2'
    height='33'
    viewBox='0 0 2 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <line
      x1='0.543945'
      y1='2.18557e-08'
      x2='0.543944'
      y2='32.5'
      stroke='#91CCEF'
    />
  </svg>
);

import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import Tooltip from '../Tooltip';

const Text = forwardRef(
  (
    {
      label = '',
      error = false,
      touched = false,
      className = '',
      note = '',
      tooltip = false,
      ...props
    },
    ref
  ) => {
    const [filled, setFilled] = useState(props.value || touched);
    const handleChange = e => {
      setFilled(true);
      props.onChange(e);
    };

    useEffect(() => {
      if (!props?.value) {
        setFilled(false);
      }
    }, [props?.value]);

    return (
      <label
        className={`${className} ${touched && error ? 'error' : ''} ${
          filled ? 'filled' : ''
        }`}
        onFocus={() => setFilled(true)}
        onBlur={() => (props.value ? setFilled(true) : setFilled(false))}
      >
        {label && (
          <p className='input-label'>
            {label}{' '}
            {tooltip && (
              <Tooltip>
                <p className='type-caption'>{tooltip}</p>
              </Tooltip>
            )}
          </p>
        )}
        <Input ref={ref} type='text' {...props} onChange={handleChange} />
        {error && touched && <p className='input-error'>{error}</p>}
        {note && (!error || !touched) && <p className='input-note'>{note}</p>}
      </label>
    );
  }
);

Text.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  touched: PropTypes.bool,
  className: PropTypes.string,
  note: PropTypes.string,
};

export default Text;

import React from 'react';

const LuvHomes = ({
  color = '#7C858D',
  backgroundColor = '#868F97',
  ...props
}) => {
  return (
    <svg
      width='136px'
      height='83px'
      viewBox='0 0 136 83'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g
        transform='translate(18.408027, 22.166172)'
        fill='none'
        fillRule='evenodd'
      >
        <polygon
          fill={color}
          points='0.0735785953 28.3465953 99.0292843 28.3465953 99.0292843 26.9952508 0.0735785953 26.9952508'
        />
        <path
          d='M0.147157191,28.2730167 L98.9557057,28.2730167 L98.9557057,27.0688294 L0.147157191,27.0688294 L0.147157191,28.2730167 Z M0,28.4201739 L99.1028629,28.4201739 L99.1028629,26.9216722 L0,26.9216722 L0,28.4201739 Z'
          fill={color}
        />
        <polygon
          fill={color}
          points='0.0735785953 30.5929498 99.0292843 30.5929498 99.0292843 29.2414582 0.0735785953 29.2414582'
        />
        <path
          d='M0.147157191,30.5193712 L98.9557057,30.5193712 L98.9557057,29.3150368 L0.147157191,29.3150368 L0.147157191,30.5193712 Z M0,30.6665284 L99.1028629,30.6665284 L99.1028629,29.1678796 L0,29.1678796 L0,30.6665284 Z'
          fill={color}
        />
        <path
          d='M47.7320535,17.4125217 C45.1341405,35.5973244 25.9588227,43.6906756 25.9588227,43.6906756 C22.3549431,42.7896321 6.58822742,33.4797324 3.88524415,18.4636656 C1.18226087,3.44759866 16.7990234,-6.01240134 25.8085753,5.69998662 C30.7639465,-3.45966555 48.7831973,-1.35752508 47.7320535,17.4125217 Z M21.264214,9.0272107 C21.0711438,9.8549699 18.9473712,17.6869699 18.3016455,22.0167759 C18.2015786,23.8730167 19.934796,25.8527224 23.2392107,26.0045886 C26.0744883,26.1349699 28.1388094,24.9412308 29.0123344,23.0801338 C30.4207288,18.2678553 32.1728372,10.7187258 32.4532985,9.15345908 L32.4686154,9.06517726 L28.0247625,9.06517726 C28.0247625,9.06517726 25.9754515,18.5113445 25.1762408,20.6873579 C24.4925485,22.5484548 22.859398,21.4469833 23.0873445,20.4975251 C23.315291,19.5479197 25.8219666,9.0272107 25.8219666,9.0272107 L25.7695103,9.02670633 C25.3076632,9.02233509 21.9361033,8.99186253 21.264214,9.0272107 Z M15.7949699,9.14111037 L11.5031304,9.14111037 L7.47720401,25.5868094 L16.5166288,25.6628896 L17.5421672,21.9027291 L12.7185017,21.9027291 L15.7949699,9.14111037 Z M37.2921338,9.0272107 L32.9623278,9.0272107 L33.6839866,25.54899 L39.4569632,25.6247759 L45.2682007,9.06517726 L40.3306355,9.06517726 C40.3306355,9.06517726 37.5199331,20.0036656 37.2921338,20.0036656 C37.0643344,20.0036656 37.2921338,9.0272107 37.2921338,9.0272107 Z'
          fill={color}
        />
        <path
          d='M16.1784615,0.739317726 C14.7121873,0.739317726 13.2425284,1.05423411 11.8323211,1.67876923 C9.1633311,2.86073579 6.91594649,5.06882943 5.50397324,7.89630769 C3.95470234,10.9989699 3.49513043,14.6350769 4.1748495,18.4115719 C6.87106355,33.3899666 22.5197592,42.4298328 25.9349833,43.3800268 C26.6186756,43.0767358 31.0501672,41.0365485 35.6956254,37.1339398 C40.3187157,33.2503144 46.1077324,26.6719465 47.4389164,17.3833846 C47.6890836,12.8818462 46.8644147,9.11756522 44.9878662,6.1947291 C43.3773779,3.68628763 41.0643612,1.94659532 38.2988361,1.16357191 C33.4242542,-0.216468227 28.2800803,1.74999331 26.0675719,5.83993311 L25.8516923,6.2387291 L25.5753311,5.87937124 C23.6812709,3.41728428 21.348388,1.76044147 18.8284682,1.08808027 C17.9559732,0.855130435 17.0677324,0.739317726 16.1784615,0.739317726 Z M25.9826622,44 L25.8874515,43.9761605 C23.9392375,43.4890702 19.082903,40.8748227 14.3857926,36.5876923 C10.2714247,32.8325351 5.05955853,26.6488428 3.5956388,18.5159064 C2.89311037,14.613592 3.37092977,10.8503411 4.97744482,7.63333779 C6.45019398,4.68371906 8.8,2.37776589 11.5940736,1.14046823 C13.9833177,0.0824080268 16.5373779,-0.132294314 18.9801873,0.519317726 C21.5058462,1.1931505 23.8454983,2.79937124 25.7694314,5.17522408 C28.2187157,1.10735786 33.4762007,-0.813779264 38.4592375,0.59716388 C41.3650033,1.41991973 43.7938328,3.24555184 45.4831973,5.87672241 C47.4299398,8.90874916 48.2853645,12.7954649 48.0257793,17.4288562 L48.0234247,17.4540201 C46.6682542,26.9399197 40.7698997,33.6433712 36.0604281,37.5963077 C30.9668763,41.8718127 26.1215786,43.9414314 26.073311,43.9618863 L25.9826622,44 L25.9826622,44 Z'
          fill={backgroundColor}
        />
        <path
          d='M49.6203746,9.21321739 L51.9316254,9.21321739 C51.9316254,9.21321739 52.289806,9.27826087 52.289806,9.63629431 C52.289806,9.99447492 52.3223278,15.1052441 52.4200401,15.1052441 C52.5177525,15.1052441 53.2663411,14.3890301 53.6895652,14.1612308 C54.1127893,13.9334314 54.7963344,13.7380067 55.5450702,13.9659532 C56.293806,14.1937525 56.8796388,14.714689 56.9774983,16.1794916 C57.0752107,17.6444415 57.0752107,22.5599331 57.0752107,23.1133913 C57.0752107,23.6668495 57.1075853,25.3921204 57.1075853,25.5548763 C57.1075853,25.7174849 56.9449766,25.9781003 56.6519866,25.9781003 C56.3588495,25.9781003 54.5034916,25.9781003 54.4057793,25.9781003 C54.3080669,25.9781003 53.9175117,25.9781003 53.9175117,25.5548763 C53.9175117,25.1316522 53.8197993,18.0675184 53.7872776,17.9047625 C53.7546087,17.7420067 53.6894181,17.3189298 53.0710635,17.3189298 C52.4525619,17.3189298 52.3873712,17.7745284 52.3873712,18.0024749 C52.3873712,18.2304214 52.4850836,25.2293645 52.4850836,25.4246421 C52.4850836,25.6199197 52.3548495,25.9454314 52.0293378,25.9454314 C51.7039732,25.9454314 49.6530435,25.9454314 49.6530435,25.9454314 C49.6530435,25.9454314 49.2947157,26.0431438 49.2623411,25.5548763 C49.2298194,25.0664615 49.2298194,9.5387291 49.2298194,9.5387291 C49.2298194,9.5387291 49.2623411,9.27840803 49.6203746,9.21321739'
          fill={backgroundColor}
        />
        <path
          d='M61.89299,22.7877324 C61.3722007,22.7552107 61.046689,21.1926957 61.046689,19.9556923 C61.046689,18.718689 61.2419666,17.1560268 61.8277993,17.123505 C62.217913,17.1018729 62.7067692,17.5142074 62.7067692,19.8905017 C62.7067692,21.7134849 62.4137793,22.8202542 61.89299,22.7877324 Z M61.89299,13.6404415 C61.4697659,13.6729632 57.6610435,13.7380067 57.6610435,19.7929365 C57.6610435,25.8477191 60.9489766,26.1733779 61.9256589,26.1733779 C62.902194,26.1733779 66.0272241,25.6849632 66.0272241,19.6952241 C66.0272241,13.7054849 61.89299,13.6404415 61.89299,13.6404415 L61.89299,13.6404415 Z'
          fill={backgroundColor}
        />
        <path
          d='M66.8084816,25.4246421 C66.8084816,25.4246421 66.8411505,26.0104749 67.2643746,26.0104749 C67.6875987,26.0104749 69.6731906,25.9781003 69.6731906,25.9781003 C69.6731906,25.9781003 70.0964147,25.9127625 70.0964147,25.5872508 C70.0964147,25.2617391 69.9816321,18.5553445 69.9988495,18.2954649 C70.0313712,17.8071973 70.1942742,17.416495 70.6823946,17.416495 C71.1706622,17.416495 71.3007492,17.7746756 71.3007492,18.3606555 C71.3007492,18.9464883 71.3334181,25.4896856 71.3334181,25.5872508 C71.3334181,25.6848161 71.5286957,26.0104749 71.8218328,26.0104749 C72.1148227,26.0104749 74.1654582,26.0106221 74.2631706,25.9781003 C74.3608829,25.9454314 74.5886823,25.7176321 74.5886823,25.4246421 C74.5886823,25.1316522 74.5563077,18.2302742 74.5563077,18.0024749 C74.5563077,17.6119197 74.8818194,17.3839732 75.1748094,17.3839732 C75.4676522,17.3839732 75.7931639,17.4491639 75.8258328,18.132709 C75.8583545,18.8164013 75.8582074,25.5222074 75.8582074,25.5222074 C75.8582074,25.5222074 75.9885886,25.9454314 76.3141003,25.9454314 C76.639612,25.9454314 78.3647358,25.9781003 78.4951171,25.9781003 C78.6254983,25.9781003 79.0159064,25.880388 79.0159064,25.6199197 C79.0159064,25.3595987 79.1136187,17.1886957 79.0485753,16.6352375 C78.9835318,16.0819264 78.7229164,14.0635184 76.9651237,14.0635184 C75.2071839,14.0635184 74.6863946,15.4634247 74.4584482,15.5284682 C74.3981137,15.4278127 73.7422341,13.9659532 72.3426221,13.9984749 C70.9428629,14.0309967 70.0965619,15.0402007 69.9988495,15.2029565 C69.9011371,15.3657124 69.9336589,14.5844548 69.9011371,14.4542207 C69.8684682,14.3239866 69.9661806,14.128709 69.5429565,14.0309967 C69.2892575,13.9724281 67.229204,14.0380602 67.1014716,14.0635184 C66.7759599,14.128709 66.8084816,14.4542207 66.8084816,14.6820201 C66.8084816,14.9099666 66.8084816,25.4246421 66.8084816,25.4246421'
          fill={backgroundColor}
        />
        <path
          d='M84.7778462,18.8489231 C84.7778462,18.8489231 83.1176187,18.8489231 82.9548629,18.8489231 C82.9548629,18.8489231 82.7269164,16.5376722 83.8663545,16.5050033 C85.0057926,16.4723344 84.7778462,18.8489231 84.7778462,18.8489231 Z M83.7686421,13.7380067 C79.7646421,13.7706756 79.7319732,19.1744348 79.7319732,19.8579799 C79.7319732,20.5415251 79.8314515,26.2554916 83.9315452,26.140709 C87.4146087,26.0431438 87.5773645,22.6900201 87.5773645,22.5272642 C87.5773645,22.3646555 87.7399732,22.1041873 87.089097,22.1041873 C86.4380736,22.1041873 85.5264348,22.136709 85.2661137,22.136709 C85.0056455,22.136709 84.8755585,22.3318395 84.8103679,22.6574983 C84.7453244,22.9831572 84.6477592,23.5364682 83.801311,23.4062341 C82.9548629,23.276 82.9875318,21.8437191 82.9875318,21.3555987 C82.9875318,21.3555987 82.8571505,21.0299398 83.3454181,21.0299398 C83.8336856,21.0299398 87.3494181,20.9323746 87.3494181,20.9323746 C87.3494181,20.9323746 87.7401204,20.8670368 87.7401204,20.4764816 C87.7401204,20.0859264 88.2934314,13.7012174 83.7686421,13.7380067 L83.7686421,13.7380067 Z'
          fill={backgroundColor}
        />
        <path
          d='M89.3353043,22.5924548 C89.3353043,22.5924548 88.4238127,24.3178729 88.3587692,24.4155853 C88.2935786,24.5131505 88.1957191,24.7734716 88.4563344,24.9363746 C88.7168027,25.0991304 90.9303411,26.6614983 92.9160803,25.6524415 C94.9018194,24.6432375 95.0647224,22.559786 95.0320535,22.0389967 C94.9993846,21.5182074 95.2795719,19.7858729 92.4278127,18.3931773 C91.0280535,17.7094849 91.3862341,16.9934181 91.5488428,16.8306622 C91.7115987,16.6679064 91.9182074,16.2442408 93.1765485,17.0584615 C93.7300067,17.416495 93.6321472,17.2537391 93.7950502,16.9934181 C93.957806,16.7329498 94.6741672,15.3657124 94.7065418,15.268 C94.7390635,15.1704348 94.8042542,14.9424883 94.4462207,14.6820201 C94.0880401,14.421699 91.9070234,13.1520268 89.9212843,14.2262742 C87.9355452,15.3006689 87.9355452,17.8395719 88.2935786,18.718689 C88.6517592,19.5976589 88.9621137,19.901097 91.2233311,21.2578863 C91.8743545,21.6484415 91.988107,22.2782742 91.7767893,22.5599331 C91.2885217,23.2109565 90.1165619,22.5924548 89.7910502,22.4622207 C89.4655385,22.3319866 89.5957726,22.3646555 89.3353043,22.5924548'
          fill={backgroundColor}
        />
        <path
          d='M95.9282408,15.5923344 C95.5849231,15.5923344 95.3552107,15.3283344 95.3552107,14.9872241 C95.3552107,14.6306622 95.5849231,14.3819666 95.9282408,14.3819666 C96.2789164,14.3819666 96.5077458,14.6439064 96.5077458,14.9872241 C96.5077458,15.3312776 96.2789164,15.5923344 95.9282408,15.5923344 Z M95.9282408,14.2455518 C95.4792642,14.2455518 95.2123211,14.5829833 95.2123211,14.9872241 C95.2123211,15.3885217 95.4792642,15.7288963 95.9282408,15.7288963 C96.3808963,15.7288963 96.6515184,15.3914649 96.6515184,14.9872241 C96.6515184,14.5829833 96.3808963,14.2455518 95.9282408,14.2455518 L95.9282408,14.2455518 Z'
          fill={backgroundColor}
        />
        <path
          d='M96.1694309,15.1783534 L95.8838641,15.1783534 L95.8838641,14.8407504 L96.174853,14.8407504 C96.3253823,14.8407504 96.4152352,14.8954165 96.4152352,15.0095519 C96.4152352,15.1177404 96.3150544,15.1783534 96.1694309,15.1783534 Z M96.4322762,15.3117019 C96.5608587,15.2570359 96.6496788,15.1531932 96.6496788,15.0095519 C96.6496788,14.7918026 96.4655837,14.6527358 96.174853,14.6527358 L95.6612977,14.6527358 L95.6612977,15.6527358 L95.8838641,15.6527358 L95.8838641,15.3606498 L96.1980908,15.3606498 L96.4322762,15.6527358 L96.6612977,15.6527358 L96.6612977,15.6035592 L96.4322762,15.3162765 L96.4322762,15.3117019 L96.4322762,15.3117019 Z'
          fill={backgroundColor}
        />
      </g>
    </svg>
  );
};

export default LuvHomes;

import Number from './Number';
import Text from './Text';
import Dropdown from './Dropdown';
import Textarea from './Textarea';
import Date from './Date';

export default {
  number: Number,
  text: Text,
  dropdown: Dropdown,
  textarea: Textarea,
  date: Date,
};

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Styles from './styled';
import TooltipIcon from '../icons/tooltip';
import theme from '../../../styles/theme';

const Tooltip = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tooltipOffset, setTooltipOffset] = useState(0);
  const buttonRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    const { left, right } = contentRef.current.getBoundingClientRect();
    const { innerWidth } = window;

    if (right > innerWidth - 16) {
      setTooltipOffset(innerWidth - 16 - right);
    } else if (left < 0) {
      setTooltipOffset(left * -1 + 16);
    }

    const handleWindowResize = () => {
      setTooltipOffset(0);

      setTimeout(() => {
        const { left, right } = contentRef.current.getBoundingClientRect();
        const { innerWidth } = window;

        if (right > innerWidth - 16) {
          setTooltipOffset(tooltipOffset + (innerWidth - 16 - right));
        } else if (left < 0) {
          setTooltipOffset(left * -1);
        }
      }, 500);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Styles
      className={`tooltip ${isOpen ? 'open' : ''}`}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
      ref={buttonRef}
      type='button'
    >
      <TooltipIcon active={isOpen} />
      <div
        className='tooltip-content'
        ref={contentRef}
        style={{ marginLeft: `${tooltipOffset}px` }}
      >
        {children}
      </div>
      <span className='tooltip-arrow' />
    </Styles>
  );
};

Tooltip.propTypes = {};

export default Tooltip;

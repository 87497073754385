import styled from 'styled-components';

export default styled.button`
  display: inline-block;
  background: transparent;
  border: none;
  padding: 0;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  outline: none;

  svg {
    * {
      transition: all 0.2s ease-in-out;
    }
  }

  .tooltip-content {
    border-radius: ${props => props.theme.borderRadiuses.tooltip};
    padding: 10px;
    position: absolute;
    width: 250px;
    z-index: 1000;
    background: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadows.tooltip};
    bottom: 100%;
    left: 50%;
    margin-bottom: 1rem;
    border: solid 1px ${props => props.theme.colors.vmfBlue};
    transition: all 0.2s ease-in-out;
    animation: animateOut ease-in-out 0.2s forwards;

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }

  .tooltip-arrow {
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 11px solid ${props => props.theme.colors.vmfBlue};
    z-index: 1001;
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translate3d(-50%, -10px, 0);
    opacity: 0;
    transition: all 0.2s ease-in-out;

    &::after {
      display: block;
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      left: -10px;
      bottom: 1px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 11px solid ${props => props.theme.colors.white};
    }
  }

  &.open {
    overflow: visible;

    .tooltip-content {
      animation: animateIn ease-in-out 0.2s forwards;
    }

    .tooltip-arrow {
      transform: translateX(-50%);
      opacity: 1;
    }
  }

  @keyframes animateIn {
    0% {
      opacity: 0;
      transform: translate3d(-50%, -10px, 0);
      display: none;
    }
    1% {
      opacity: 0;
      transform: translate3d(-50%, -10px, 0);
      display: block;
    }
    100% {
      opacity: 1;
      transform: translateX(-50%);
      display: block;
    }
  }

  @keyframes animateOut {
    0% {
      opacity: 1;
      transform: translateX(-50%);
      display: block;
    }
    99% {
      opacity: 0;
      transform: translate3d(-50%, -10px, 0);
      display: block;
    }
    100% {
      opacity: 0;
      transform: translate3d(-50%, -10px, 0);
      display: none;
    }
  }
`;

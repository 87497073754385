import styled from 'styled-components';

export default styled.div`
  ${({ theme, backgroundColor, backgroundImage, mobileBackgroundImage }) => `
    background-color: ${backgroundColor};
    position: relative;
    min-height: 49.875rem;

    .ppc-hero-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(35, 31, 32, 0.45);
      z-index: 1;
    }

    .ppc-hero-intro {
      color: ${theme.colors.white};
    }

    .ppc-header-content-banner {
      position: relative;
      z-index: 2;
      padding-top: 10.6rem;

      &__inner {
        max-width: 38.375rem;
        padding-bottom: 3.875rem;
      }
      
    }

    ${
      backgroundImage
        ? `
          background-image: url("${backgroundImage}");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        `
        : ''
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
      ${
        mobileBackgroundImage
          ? `
            background-image: url("${mobileBackgroundImage}");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          `
          : ''
      }
    }

    .ppc-hero-header {
      color: ${theme.colors.babyBlueTint};
      margin-bottom: 1.62875rem;
    }

    .ppc-hero-sub-header {
      color: ${theme.colors.white};
      margin-bottom: 0.4125rem;
      margin-top: 1.44875rem;
    }

    .ppc-header-secondary-header {
      color: ${theme.colors.yellow};
    }

    .ppc-header-disclaimer {
      margin-top: 1.95rem;
    }

    @media (max-width: ${theme.breakpoints.desktop}) {
      min-height: 0;
    }
    
  `}
`;

import React, { useMemo } from 'react';
import SimpleAnimation from '../../styles/Components/SimpleAnimation';
import theme from '../../styles/theme';
import InView from '../Shared/InView';
import Styles from './SnippetSection.styled';
import ReactMarkdown from 'react-markdown';

const themes = {
  White: {
    background: theme.colors.white,
    heading: theme.colors.darkBlue,
    text: theme.colors.grey1,
  },
  'Grey-7': {
    background: theme.colors.grey7,
    heading: theme.colors.darkBlue,
    text: theme.colors.grey1,
  },
};

const SnippetSection = ({
  theme = themes['White'],
  heading = '',
  snippets = [
    {
      header: '',
      iconUrl: '',
      iconAlt: '',
      text: '',
    },
  ],
}) => {
  const snippetTheme = useMemo(() => themes[theme] || themes['White'], [theme]);

  return (
    <InView>
      <Styles colors={snippetTheme}>
        {heading && (
          <SimpleAnimation.h2
            className='snippet-section__heading'
            slideDirection='up'
          >
            {heading}
          </SimpleAnimation.h2>
        )}
        {snippets?.length ? (
          <div className='snippet-section__snippets'>
            {snippets
              .filter(snippet => snippet.iconUrl && snippet.text)
              .map((snippet, i) => (
                <SimpleAnimation.div
                  key={`snippet-section-snippet-${i}`}
                  className='snippet-section__snippet'
                  delay={0.2 + i * 0.2}
                  slideDirection='up'
                >
                  <img src={snippet.iconUrl} alt={snippet.iconAlt} />
                  {snippet?.header && (
                    <h5 className='snippet-section__snippet__header'>
                      {snippet.header}
                    </h5>
                  )}
                  <p className='snippet-text'>
                    {<ReactMarkdown source={snippet.text} />}
                  </p>
                </SimpleAnimation.div>
              ))}
          </div>
        ) : null}
      </Styles>
    </InView>
  );
};

export default SnippetSection;

import styled from 'styled-components';

const maxWidth = '1222px';

export default styled.section`${({ theme, hasSubHeader, hasFooter }) => `
  box-sizing: border-box;
  padding: 4.7206rem ${theme.padding.inner} 2rem ${theme.padding.inner};

  @media (max-width: ${theme.breakpoints.tablet}) {
    padding: 3.375rem ${theme.padding.inner} 3rem ${theme.padding.inner};
  }

  .calculator-section-header, .calculator-section-sub-header {
    text-align: center;
    max-width: ${maxWidth};
    margin: auto;
  }

  .calculator-section-header {
    margin-bottom: ${hasSubHeader ? '0.9444rem' : '2.25rem'};
  }

  .calculator-section-sub-header {
    margin-bottom: 2.25rem;
  }

  .calculator-section-footer {
    max-width: ${maxWidth};
    margin: auto;
  }

  .calculator-section-calculator {
    padding: 0;
    margin-bottom: ${hasFooter ? '2.75rem' : 0};

    .inner {
      max-width: ${maxWidth};

      .calculator .results {
        margin-right: 0;
      }
      
    }
  }
`}`;

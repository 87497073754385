import styled from 'styled-components';

export default styled.section`
  background: ${props => props.colors.background};
  box-sizing: border-box;
  padding: 5.63rem 6.64125rem;

  h1, p {
    margin: 0;
    padding: 0;
  }

  h1 {
    color: ${props => props.colors.headerColor};    
  }
  
  .customer-stories-inner {
    max-width: ${({ theme }) => theme.maxWidths.ppcPages};
    margin: auto;
  }
  
  .customer-stories-content {
    display: grid;
    grid-template-columns: 36.75rem 36.75rem;
    grid-column-gap: 3rem;
    grid-row-gap: 4.5rem;
    margin-top: 3.8125rem;

    .customer-story {
      box-sizing: border-box;
      display: flex;
    }

    .customer-story-content * {
      font-family: ${({ theme }) => theme.fonts.primary};
      font-weight: 400;
      font-size: ${({ theme }) => theme.type.paragraph.caption};
      line-height: 1.375rem;
      color: ${props => props.colors.storyBodyColor};
    }

    .customer-story-image {
      img, picture {
        height: 11.5rem;
        width: 11.5rem;
      }
    }

    .customer-story-details {
      ${({ theme, colors }) => `
        margin-left: 1.5rem;
        max-width: 23.75rem;

        &__customer-name {
          display: block;
          font-family: ${theme.fonts.primary};
          font-weight: 700;
          font-size: 0.6875rem;
          line-height: 0.8125rem;
          letter-spacing: 0.5px;
          color: ${colors.storyNameColor};
          text-transform: uppercase;
          margin-top: 1.375rem;

          &:before {
            content: "- ";
          }

        }
        
      `}
    }

  }

  @media (max-width: ${props => props.theme.breakpoints.desktopLarge}) {
    .customer-stories-content {
      display: flex;
      flex-direction: column;
  
      .customer-story-details {
        max-width: 100%;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    
    .customer-stories-content {
      margin-top: 2.6875rem;

      .customer-story-details {
        margin-left: 0;
        margin-top: 1.2763rem;

        &__customer-name {
          margin-top: 1rem;
        }
        
      }

      .customer-story {
        flex-direction: column;
      }
      
    }

  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 4.2188rem ${({ theme }) => theme.padding.inner};
  }
  
`;

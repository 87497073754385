import styled from 'styled-components';

export default styled.section`
  ${({ theme }) => `
    min-height: 45.625rem;
    position: relative;

    .video-image-section {
      width: 100%;
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      top: 5rem;
      box-sizing: border-box;

      @media (max-width: ${theme.breakpoints.desktop}) {
        max-width: ${theme.maxWidths.containerSmall};
        padding: 2.5625rem ${theme.padding.inner} 3.25rem ${theme.padding.inner};
        top: 0;
        margin: auto;
      }

      h2 {
        margin: 0;
        line-height: 2.25rem;
        letter-spacing: -0.5px;
        color: ${theme.colors.darkBlue};
        margin-bottom: 0.718125rem;
        text-align: center;
      }

      p {
        margin: 0;
        letter-spacing: 0px;
        margin-bottom: 3.375rem;
        text-align: center;
      }

      &__content {
        max-width: 50.75rem;
        height: 28.584375rem;
        width: 100%;

        *:first-child:not(picture, source, div), img {
          box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        
      }

    }

    @media (max-width: ${theme.breakpoints.desktop}) {
      min-height: initial;

      .video-image-section__content {
        height: initial;
      }
    }

    .video-image-thumbnail {
      position: relative;
    }

    .play-button {
      display: flex;
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      background: ${theme.colors.white};

      p {
        line-height: 1em;
        margin: 0;
        margin-left: 0.5rem;
      }

      &:hover,
      &:active,
      &:focus {
        background: ${theme.colors.white};
        color: ${theme.colors.vmfGreen2};
      }
    }
    
  `}
`;

import styled from 'styled-components';

export default styled.div`
  position: relative;
  h1, h2, h3, h4, h5, p {
    margin: 0;
  }

  .ppc-web-form {
    margin-top: 2.0625rem;
    margin-right: 1.5625rem;
  }

  .ppc-image-banner {
    picture {
      height: 100%;
      width: 100%;
      
      img {
        height: 25.4375rem;
        width: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {

    .ppc-image-banner {
      &__desktop {
        display: block;
      }
      &__mobile {
        display: none;
      }
    }

  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    .ppc-image-banner {
      &__desktop {
        display: none;
      }
      &__mobile {
        display: block;
      }
    }
  }

  .ppc-header-content-banner {
    padding: 0 ${props => props.theme.padding.inner};
    max-width: ${props => props.theme.maxWidths.ppcPages};
    margin: auto;

    &__inner {
      width: 50%;
      max-width: 32.0625rem;
      padding-bottom: 4.04875rem;

      @media (max-width: ${props => props.theme.breakpoints.desktop}) {
        width: 100%;
        max-width: 100% !important;
      }
      
    }
    
  }

  .ppc-hero-intro {
    font-family: nunito;
    font-weight: 400;
    font-size: ${props => props.theme.type.paragraph.default};
    line-height: 27px;
  }

  .ppc-large-heading {
    font-weight: 800;
    font-size: ${props => props.theme.type.large};
    line-height: 64px;
    letter-spacing: -1.25px;

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.type.largeMobile};
    }
    
  }

  .ppc-h1-heading {
    font-weight: 800;
    font-size: ${props => props.theme.type.h1};
    line-height: 45px;
    letter-spacing: -1px;

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.type.h1Mobile}
    }
  }

  .ppc-h3-secondary-header {
    font-weight: 800;
    font-size: ${props => props.theme.type.h3};
    line-height: 28px;
    letter-spacing: -0.5px;

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.type.h3Mobile}
    }
  }

  .ppc-h5-sub-header {
    font-weight: 800;
    font-size: ${props => props.theme.type.h5};
    line-height: 19px;
    letter-spacing: -.25px;

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.type.h5Mobile}
    }
  }

  .ppc-home-data {
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.white};
    width: auto;
    padding-right: 1.6rem;
    margin: 1.44875rem 0;

    &__mobile {
      margin: 1.74875rem 0;
      display: none;
      border-bottom: none;
    }

    &__mobile-details {
      margin-top: 0.6625rem;
      padding-top: 0.68125rem;
      border-top: 1px solid ${props => props.theme.colors.white};
      display: inline-flex;
      align-items: center;
    }

    &__line-split {
      margin-right: 1.328125rem;
    }

    h5, &__bullet {
      margin-top: -0.2625rem;
    }

    &__bullet {
      margin-right: 0.839375rem;
    }
    
    h5 {
      font-weight: 800;
      font-size: ${props => props.theme.type.h5};
      line-height: 19px;
      letter-spacing: -.25px;
      color: ${props => props.theme.colors.white};

      &:nth-of-type(1) {
        margin-right: 1.28125rem;
      }

      &:nth-of-type(2) {
        margin-right: 0.84375rem;
      }

      &:nth-of-type(3) {
        margin-right: 0.839375rem;
      }
      
    }
    
  }

  .ppc-header-disclaimer {
    margin-top: 1.84875rem;

    p {
      font-family: nunito;
      font-weight: 400;
      font-size: ${props => props.theme.type.paragraph.xs};
      line-height: 16px;
      color: ${props => props.theme.colors.white};
    }

    a {
      color: ${props => props.theme.colors.white};

      &:hover,
      &:focus,
      &:active {
        color: ${props => props.theme.colors.vmfGreen2};
      }
      
    }
    
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {

    .ppc-home-data {
      display: none;

      &__mobile {
        display: inline-block;
      }
      
    }
    
  }

  @media (max-width: ${props => props.theme.breakpoints.desktop}) {
    display: flex;
    flex-direction: column;

    .ppc-web-form {
      margin-top: 0;
      margin-right: 0;
    }
    
  }

`;
